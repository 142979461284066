import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    CoverageSelectKey,
    QrsUnitDetailsFormValue,
    QuoteRigSetFormValue,
} from '@backend-types/quote-flow-ens';
import { RocUnitClass, UnitCollDeductible, UnitCompDeductible } from '@backend-types/roc-lib';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { valueLimits } from '@data/value-limits.data';
import { QuoteRigSetFormService, RoundingService } from '@modules/quote/services';
import { debounceTime, Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-rig-set-form-camper-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-rig-set-form-camper-details.component.html',
    styleUrls: ['quote-rig-set-form-camper-details.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteRigSetFormCamperDetailsComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteRigSetFormCamperDetailsComponent,
        },
    ],
})
export class QuoteRigSetFormCamperDetailsComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    valueLimits = valueLimits;
    camperValueRounded = false;

    camperDetailsForm: ModelFormGroup<QrsUnitDetailsFormValue> = this.fb.group({
        unitValue: new FormControl<number | null>(null, [
            Validators.required,
            Validators.min(valueLimits.camper.min),
            Validators.max(valueLimits.camper.max),
        ]),
        unitLength: new FormControl<number | null>(null, [
            Validators.required,
            Validators.max(100),
        ]),
        unitAge: new FormControl<number | null>(null, [Validators.required, Validators.max(20)]),
        unitOwnedTenure: new FormControl<number | null>(null, [
            Validators.required,
            Validators.max(20),
        ]),
        unitMake: new FormControl<string | null>(null, [Validators.required]),
        unitModel: new FormControl<string | null>(null, [Validators.required]),
        unitClass: new FormControl<RocUnitClass | null>(null, [Validators.required]),
        unitCompDeductible: new FormControl<UnitCompDeductible | null>(null, [Validators.required]),
        unitCollDeductible: new FormControl<UnitCollDeductible | null>(null, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (camperDetails: ModelFormValue<QrsUnitDetailsFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private roundingService: RoundingService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Camper Details');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_camper_details',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.camperDetailsForm.patchValue({
                    unitValue: 10000,
                    unitLength: 10,
                    unitAge: 5,
                    unitOwnedTenure: 5,
                    unitMake: 'MAKE',
                    unitModel: 'MODEL',
                });
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );

        this.subscription.add(
            this.unitValueControl.valueChanges.pipe(debounceTime(600)).subscribe((unitValue) => {
                this.camperValueRounded =
                    this.roundingService.roundInputValue({
                        inputValue: unitValue,
                        inputValueControl: this.unitValueControl,
                        minValue: valueLimits.camper.min,
                        roudedTo: valueLimits.camper.roundedTo,
                    }) || this.camperValueRounded;

                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (camperDetails: ModelFormValue<QrsUnitDetailsFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.camperDetailsForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.camperDetailsForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const camperDetails = this._camperDetailsFormValue();
                        if (this.unitValueControlValid) {
                            onChange(camperDetails);
                        }
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.camperDetailsForm.disable();
        } else {
            this.camperDetailsForm.enable();
        }
    }

    writeValue(value: QrsUnitDetailsFormValue | null) {
        if (value === null) {
            this.camperDetailsForm.reset();
        }
        if (value) {
            this.camperDetailsForm.setValue(value, { emitEvent: false });
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.camperDetailsForm?.invalid) {
            return { camperDetailsFormInvalid: true };
        }

        return null;
    }

    private _camperDetailsFormValue(): ModelFormValue<QrsUnitDetailsFormValue> {
        const {
            unitValue,
            unitLength,
            unitAge,
            unitOwnedTenure,
            unitMake,
            unitModel,
            unitClass,
            unitCompDeductible,
            unitCollDeductible,
        } = this.camperDetailsForm.value;

        this.assertionService.isDefinedOrThrow(unitValue);
        this.assertionService.isDefinedOrThrow(unitLength);
        this.assertionService.isDefinedOrThrow(unitAge);
        this.assertionService.isDefinedOrThrow(unitOwnedTenure);
        this.assertionService.isNotUndefinedOrThrow(unitMake);
        this.assertionService.isNotUndefinedOrThrow(unitModel);
        this.assertionService.isDefinedOrThrow(unitClass);
        this.assertionService.isDefinedOrThrow(unitCompDeductible);
        this.assertionService.isDefinedOrThrow(unitCollDeductible);

        return {
            unitValue,
            unitLength: parseInt(unitLength as unknown as string, 10),
            unitAge: parseInt(unitAge as unknown as string, 10),
            unitOwnedTenure: parseInt(unitOwnedTenure as unknown as string, 10),
            unitMake,
            unitModel,
            unitClass,
            unitCompDeductible,
            unitCollDeductible,
        };
    }

    goNext() {
        this.quoteRigSetFormService.updateRigCoverageSelect(CoverageSelectKey.camperCoverage, true);
        this.next.emit();
    }

    /* Accessor Methods */

    get unitMakeControl() {
        return this.camperDetailsForm.get('unitMake') as FormControl;
    }

    get unitMakeControlValid() {
        return this.unitMakeControl.value && !this.unitMakeControl.hasError('required');
    }

    get unitMakeControlInvalid() {
        return this.unitMakeControl.touched && this.unitMakeControl.hasError('required');
    }

    get unitModelControl() {
        return this.camperDetailsForm.get('unitModel') as FormControl;
    }

    get unitModelControlValid() {
        return this.unitModelControl.value && !this.unitModelControl.hasError('required');
    }

    get unitModelControlInvalid() {
        return this.unitModelControl.touched && this.unitModelControl.hasError('required');
    }

    get unitAgeControl() {
        return this.camperDetailsForm.get('unitAge') as FormControl;
    }

    get unitAgeControlValid() {
        return this.unitAgeControl.value && this.unitAgeControl.valid;
    }

    get unitAgeControlInvalid() {
        return this.unitAgeControl.touched && this.unitAgeControl.invalid;
    }

    get unitLengthControl() {
        return this.camperDetailsForm.get('unitLength') as FormControl;
    }

    get unitLengthControlValid() {
        return (
            this.unitLengthControl.value &&
            !(this.unitLengthControl.hasError('required') || this.unitLengthControl.hasError('max'))
        );
    }

    get unitLengthControlInvalid() {
        return (
            this.unitLengthControl.touched &&
            (this.unitLengthControl.hasError('required') || this.unitLengthControl.hasError('max'))
        );
    }

    get unitOwnedTenureControl() {
        return this.camperDetailsForm.get('unitOwnedTenure') as FormControl;
    }

    get unitOwnedTenureControlValid() {
        return (
            this.unitOwnedTenureControl.value &&
            !(
                this.unitOwnedTenureControl.hasError('required') ||
                this.unitOwnedTenureControl.hasError('max')
            )
        );
    }

    get unitOwnedTenureControlInvalid() {
        return (
            this.unitOwnedTenureControl.touched &&
            (this.unitOwnedTenureControl.hasError('required') ||
                this.unitOwnedTenureControl.hasError('max'))
        );
    }

    get unitClassControl() {
        return this.camperDetailsForm.get('unitClass') as FormControl;
    }

    get unitClassControlValid() {
        return this.unitClassControl.value && !this.unitClassControl.hasError('required');
    }

    get unitClassControlInvalid() {
        return this.unitClassControl.touched && this.unitClassControl.hasError('required');
    }

    get unitValueControl() {
        return this.camperDetailsForm.get('unitValue') as FormControl;
    }

    get unitValueControlValid() {
        return (
            this.unitValueControl.value &&
            !(
                this.unitValueControl.hasError('required') ||
                this.unitValueControl.hasError('min') ||
                this.unitValueControl.hasError('max')
            )
        );
    }

    get unitValueControlInvalid() {
        return (
            (this.unitValueControl.touched ||
                this.assertionService.isDefined(this.unitValueControl.value)) &&
            (this.unitValueControl.hasError('required') ||
                this.unitValueControl.hasError('min') ||
                this.unitValueControl.hasError('max'))
        );
    }

    // eslint-disable-next-line complexity
    get allValid() {
        return (
            this.unitAgeControlValid &&
            this.unitLengthControlValid &&
            this.unitOwnedTenureControlValid &&
            this.unitClassControlValid &&
            this.unitValueControlValid &&
            this.unitMakeControlValid &&
            this.unitModelControlValid
        );
    }
}
