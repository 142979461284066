import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { AnalyticsService, AssertionService, EnvService, UtilityService } from '@common/services';
import { ValidateCheckPasswords } from '@common/validators';
import { AuthService } from '@modules/auth/services';

interface CreatePasswordFormValue {
    password: string;
    confirmPassword: string;
}

@Component({
    selector: 'sbf-create-password-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './create-password-form.component.html',
    styleUrls: ['create-password-form.component.scss'],
})
export class CreatePasswordFormComponent implements OnInit {
    @Input() userID!: string;
    @Input() email!: string;
    @Input() passwordResetToken!: string;

    createPasswordForm = this.fb.group(
        {
            // email: ['asd', [Validators.required]],
            email: new FormControl<string | null>({ value: null, disabled: true }, [
                Validators.required,
            ]),
            password: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', [Validators.required]],
        },
        { validators: [ValidateCheckPasswords.validate] }
    );

    constructor(
        private fb: FormBuilder,
        private authService: AuthService,
        private changeDetectorRef: ChangeDetectorRef,
        private utilityService: UtilityService,
        private analyticsService: AnalyticsService,
        private envService: EnvService,
        private assertionService: AssertionService
    ) {}
    ngOnInit() {
        this.emailControl.setValue(this.email);
    }

    onSubmit() {
        if (this.createPasswordForm.status === 'VALID') {
            if (this.utilityService.localStorageClean.getItem('sb-testing')) {
                return this._createPassword('TESTING');
            } else {
                this.utilityService.window.grecaptcha.ready(() => {
                    this.utilityService.window.grecaptcha
                        .execute(this.envService.config.recaptchaSiteKey, {
                            action: 'createPassword',
                        })
                        .then((token) => {
                            this._createPassword(token);
                        });
                });
            }
        }
        this.createPasswordForm.markAllAsTouched();
    }

    private _createPassword(token: string) {
        const createPasswordFormValue = this._createPasswordFormValue();

        this.authService
            .createPassword$({
                password: createPasswordFormValue.password,
                userID: this.userID,
                passwordResetToken: this.passwordResetToken,
                grToken: token,
            })
            .subscribe({
                next: () => {
                    // this.analyticsService.sendEventSignUp('account');
                },
                error: (error) => {
                    // if (error.error.message === 'EMAIL_IN_USE') {
                    //     this.emailInUse = true;
                    //     this.changeDetectorRef.detectChanges();
                    // }
                },
            });
    }

    _createPasswordFormValue(): CreatePasswordFormValue {
        const { password, confirmPassword } = this.createPasswordForm.value;

        this.assertionService.isDefinedOrThrow(password);
        this.assertionService.isDefinedOrThrow(confirmPassword);

        return {
            password,
            confirmPassword,
        };
    }

    /* Accessor Methods */

    get emailControl() {
        return this.createPasswordForm.get('email') as FormControl;
    }

    get passwordControl() {
        return this.createPasswordForm.get('password') as FormControl;
    }

    get passwordControlValid() {
        return this.passwordControl.touched && !this.passwordControlInvalid;
    }

    get passwordControlInvalid() {
        return (
            this.passwordControl.touched &&
            (this.passwordControl.hasError('required') ||
                this.passwordControl.hasError('minlength'))
        );
    }

    get confirmPasswordControl() {
        return this.createPasswordForm.get('confirmPassword') as FormControl;
    }

    get confirmPasswordControlValid() {
        return this.confirmPasswordControl.touched && !this.confirmPasswordControlInvalid;
    }

    get confirmPasswordControlInvalid() {
        return (
            this.confirmPasswordControl.touched &&
            (this.confirmPasswordControl.hasError('required') ||
                this.confirmPasswordControl.hasError('minlength') ||
                this.createPasswordForm.hasError('passwordMismatch'))
        );
    }
}
