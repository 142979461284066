import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtmService } from '@common/services';

@Injectable()
export class PartnerService {
    constructor(
        private http: HttpClient,
        private utmService: UtmService
    ) {}

    smPostback(): void {
        const successfulMedia = this.utmService.successfulMedia;

        if (successfulMedia) {
            this.http
                .get(
                    `https://successfulmedia.go2cloud.org/aff_lsr?offer_id=7&transaction_id=${successfulMedia.transactionId}`,
                    { responseType: 'text' }
                )
                .subscribe();
        }
    }
}
