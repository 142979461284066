import { Component, OnInit } from '@angular/core';
import {
    AnalyticsService,
    EnvService,
    OnlineService,
    PlatformService,
    ScriptService,
    UpdateService,
    UtilityService,
} from '@common/services';
import { NavigationService } from '@modules/navigation/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    delayedLoadingHasLoaded = false;
    constructor(
        // Need to import AnalyticsService here to initialize singleton.
        // That way it will listen to the router on all routes.
        private analyticsService: AnalyticsService,
        private updateService: UpdateService,
        private envService: EnvService,
        private scriptService: ScriptService,
        private utilityService: UtilityService,
        private onlineService: OnlineService,
        private platformService: PlatformService,
        private navigationService: NavigationService
    ) {}
    ngOnInit() {
        if (this.platformService.isServer) {
            // INFO: We are prerendering, no need to load external.
            return;
        }
        if (!this.utilityService.window.navigator.onLine) {
            // INFO: Offline, no need to load external unless we go back online.
            this.onlineService.showOffLine();
            this.onlineService.backOnline$.subscribe(() => this._initExternal());
            return;
        }

        this._initExternal();
    }

    _initExternal() {
        setTimeout(() => {
            this.navigationService.shouldLoadDeferredService$().subscribe((shouldLoad) => {
                if (shouldLoad) {
                    this._delayedLoading();
                }
            });
        }, 5000);
    }

    _delayedLoading() {
        if (this.delayedLoadingHasLoaded) {
            return;
        }

        this.scriptService.loadScript('grecaptcha');
        this.utilityService.window.intercomSettings = {
            api_base: 'https://api-iam.intercom.io',
            app_id: 'tpqkc4pf',
        };
        this.delayedLoadingHasLoaded = true;

        this.scriptService.loadScript('intercom');
    }
}
