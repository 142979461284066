export interface FAQ {
    question: string;
    answer: string[];
    category: FaqCategories;
}

export type FaqFilter = FaqFilterFromCategory | FaqFilterFromSearch;

interface FaqFilterFromCategory {
    type: 'category';
    value: FaqCategories;
}

interface FaqFilterFromSearch {
    type: 'search';
    value: string;
}

export enum FaqCategories {
    billing = 'billing',
    claims = 'claims',
    coverage = 'coverage',
    general = 'general',
}
