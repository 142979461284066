export enum QuoteEnsExclusion {
    age = 'age',
    license = 'license',
    weather = 'weather',
    noInsurance = 'noInsurance',
    overTwoViolations = 'overTwoViolations',
    overTwelveLiftKit = 'overTwelveLiftKit',
}

export interface QuoteEnsExclusionConfig {
    message: string;
    queryParam: string;
    action?: string;
}

export const quoteEnsExclusionConfigs: EnumMap<QuoteEnsExclusion, QuoteEnsExclusionConfig> = {
    age: {
        message: 'You must be 25 years of age or older',
        queryParam: 'age',
    },
    license: {
        message: 'You must have a valid license',
        queryParam: 'license',
    },
    weather: {
        message: 'You are currently in an area experiencing sever weather',
        queryParam: 'weather',
        action: 'Please check back again later',
    },
    noInsurance: {
        message: 'No current insurance policy present',
        queryParam: 'no-insurance',
    },
    overTwoViolations: {
        message: 'More than two at-fault accidents or violations in the past 3 years',
        queryParam: 'over-two-violations',
    },
    overTwelveLiftKit: {
        message: 'Lift kit in excess of 12 inches',
        queryParam: 'over-twelve-lift-kit',
    },
};
