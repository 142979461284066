import type { Prisma, QuoteEns } from './_prisma';
import type { ExistingCoveragePullStatus } from './coverage-verification';
import type { QuoteEnsGeneratedDocuments } from './document';
import type { QuoteEnsExclusion } from './exclusions';
import type { ExistingCoverageTyped } from './existing-coverage';
import type { PolicyEnsTyped } from './policy-ens';
import type { PolicyFlagTyped } from './policy-flag';
import type { QuoteEnsExcludedCache, QuoteEnsFlowFormValue } from './quote-flow-ens';
import type { RigWithModificationsTyped } from './rig';
import type { CalculateRateEnsOnlyResponse, InsuranceScoreDetail } from './roc-lib';
import {
    userForBaseInclude,
    type UserForBaseSelect,
    type UserForBaseTyped,
    type UserWithAccountDetailSet,
} from './user';

export type QuoteEnsWithRigs = Prisma.QuoteEnsGetPayload<{
    include: {
        rigs: {
            include: {
                modifications: true;
            };
        };
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
    };
}>;

export const quoteEnsWithRigsInclude = {
    rigs: {
        include: {
            modifications: true,
        },
    },
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
};

export type QuoteEnsForResults = Prisma.QuoteEnsGetPayload<{
    include: {
        rigs: {
            include: {
                modifications: true;
            };
        };
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
    };
}>;

export type QuoteEnsWithUser = Prisma.QuoteEnsGetPayload<{
    include: {
        rigs: {
            include: {
                modifications: true;
            };
        };
        user: {
            include: {
                accountDetail: true;
            };
        };
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
        utm: true;
        existingCoverages: true;
        policyFlags: true;
    };
}>;

export const quoteEnsWithUserInclude = {
    rigs: {
        include: {
            modifications: true,
        },
    },
    user: {
        include: {
            accountDetail: true,
        },
    },
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
    utm: true,
    existingCoverages: true,
    policyFlags: true,
};

export type QuoteEnsWithRoc = Prisma.QuoteEnsGetPayload<{
    include: {
        rigs: {
            include: {
                modifications: true;
            };
        };
        roc: true;
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
    };
}>;

export const quoteEnsWithRocInclude = {
    rigs: {
        include: {
            modifications: true,
        },
    },
    roc: true,
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
};

export type QuoteEnsWithPolicyEns = Prisma.QuoteEnsGetPayload<{
    include: {
        rigs: {
            include: {
                modifications: true;
            };
        };
        policyEns: true;
        organization: {
            select: {
                id: true;
                name: true;
            };
        };
        user: {
            select: UserForBaseSelect;
        };
    };
}>;

export type QuoteEnsWithExistingCoverages = Prisma.QuoteEnsGetPayload<{
    include: {
        existingCoverages: true;
    };
}>;

export const quoteEnsWithPolicyEnsInclude = {
    rigs: {
        include: {
            modifications: true,
        },
    },
    policyEns: true,
    organization: {
        select: {
            id: true,
            name: true,
        },
    },
    user: userForBaseInclude,
};

export interface CreateQuoteEnsPayload {
    rocId: UUID;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
    quoteEnsDocuments: QuoteEnsGeneratedDocuments;
    excludedCache?: QuoteEnsExcludedCache[];
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    quoteKind: QuotePolicyKind;
    userId: UUID;
    organizationId: UUID;
    sourcePolicyEnsId?: UUID;
    rigIds?: UUID[];
    test?: boolean;
    ipAddress: string;
    createdAt?: string; // INFO: used for internal/generate-quotes
}

export interface ReadMultipleQuotesEnsPayload {
    quoteEnsIDs: string[];
}

export interface DeleteMultipleQuotesEnsPayload {
    quoteEnsIDs: string[];
}

export interface UpdateQuoteEnsPayload {
    quoteEnsFlowFormValue?: QuoteEnsFlowFormValue;
    closed?: boolean;
    test?: boolean;
    userId?: UUID;
}

export type AllQuotesEnsErrorCodes =
    | 'ERROR_FINDING_QUOTES_ENS'
    | 'ERROR_FINDING_QUOTES_ENS_WITH_ROC'
    | 'QUOTES_ENS_NOT_FOUND'
    | 'QUOTES_ENS_WITH_ROC_NOT_FOUND';
export type CreateQuotesEnsErrorCodes = 'QUOTE_ENS_NAME_IN_USE' | 'ERROR_CREATING_QUOTE_ENS';
export type DeleteMultipleQuotesEnsErrorCodes =
    | 'QUOTES_ENS_NOT_FOUND'
    | 'ERROR_FINDING_QUOTES_ENS'
    | 'ERROR_DELETING_QUOTES_ENS';
export type DeleteQuoteEnsErrorCodes =
    | 'QUOTE_ENS_NOT_FOUND'
    | 'ERROR_FINDING_QUOTE_ENS'
    | 'ERROR_DELETING_QUOTE_ENS';
export type FindQuoteEnsErrorCodes =
    | 'ERROR_FINDING_QUOTE_ENS'
    | 'ERROR_FINDING_QUOTE_ENS_WITH_USER'
    | 'ERROR_FINDING_QUOTE_ENS_WITH_POLICY'
    | 'QUOTE_ENS_NOT_FOUND'
    | 'QUOTE_ENS_WITH_USER_NOT_FOUND'
    | 'QUOTE_ENS_WITH_POLICY_NOT_FOUND';
export type FindQuotesEnsErrorCodes = 'ERROR_FINDING_QUOTES_ENS' | 'NO_QUOTES_ENS_FOUND';
export type SaveQuoteEnsErrorCodes = 'ERROR_SAVING_QUOTE_ENS' | 'QUOTE_ENS_IS_NOT_ACTIVE';
export type UpdateQuoteEnsErrorCodes =
    | 'ERROR_UPDATING_QUOTE_ENS'
    | 'QUOTE_ENS_NOT_FOUND'
    | 'QUOTE_ENS_IS_NOT_ACTIVE';

export interface EnsByIdResponse {
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    userId: UUID;
}

export interface EmailQuoteResponse {
    result: string;
}

export interface EnsPayload {
    rocID: UUID | 'latest';
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
}

export interface CalculateRateEnsOnlyParams {
    rocID: UUID | 'latest';
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    quoteKind: QuotePolicyKind;
    creditScore: InsuranceScoreDetail;
}

export interface EnsResponse {
    ensOnly: CalculateRateEnsOnlyResponse | null;
    quoteEnsId: UUID;
    quoteEnsQuoteNumber: number;
    userId: UUID;
    exclusion?: QuoteEnsExclusion;
    quoteEnsDocuments?: QuoteEnsGeneratedDocuments;
    existingCoverages?: ExistingCoverageTyped[];
}

export interface EnsResponseWithEnsOnly extends EnsResponse {
    ensOnly: CalculateRateEnsOnlyResponse;
}

export interface EnsUpdatePayload {
    rocID: UUID | 'latest';
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    quoteID: UUID;
    generateDocs?: boolean;
    updateAddress?: boolean;
}

export enum QuotePolicyKind {
    new = 'new',
    renewal = 'renewal',
    endorsement = 'endorsement',
    reinstatement = 'reinstatement',
}

export enum QuoteStatus {
    active = 'active',
    converted = 'converted',
    expired = 'expired',
}

export interface QuoteEnsTyped
    extends Omit<
        QuoteEns,
        | 'quoteEnsFlowFormValue'
        | 'calculateRateEnsOnlyResponse'
        | 'quoteEnsDocuments'
        | 'excludedCache'
    > {
    quoteKind: QuotePolicyKind;
    quoteStatus: QuoteStatus;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
    quoteEnsDocuments: QuoteEnsGeneratedDocuments;
    excludedCache: QuoteEnsExcludedCache[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
}

export interface QuoteEnsForResultsTyped
    extends Omit<
        QuoteEnsForResults,
        | 'quoteEnsFlowFormValue'
        | 'calculateRateEnsOnlyResponse'
        | 'quoteEnsDocuments'
        | 'excludedCache'
        | 'rigs'
    > {
    rigs: RigWithModificationsTyped[];
    quoteKind: QuotePolicyKind;
    quoteStatus: QuoteStatus;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
    quoteEnsDocuments: QuoteEnsGeneratedDocuments;
    excludedCache: QuoteEnsExcludedCache[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
}

export interface QuoteEnsWithUserTyped
    extends Omit<
        QuoteEnsWithUser,
        | 'quoteEnsFlowFormValue'
        | 'calculateRateEnsOnlyResponse'
        | 'quoteEnsDocuments'
        | 'excludedCache'
        | 'rigs'
        | 'user'
        | 'policyFlags'
        | 'existingCoverages'
    > {
    rigs: RigWithModificationsTyped[];
    quoteKind: QuotePolicyKind;
    quoteStatus: QuoteStatus;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
    quoteEnsDocuments: QuoteEnsGeneratedDocuments;
    excludedCache: QuoteEnsExcludedCache[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    user: UserWithAccountDetailSet;
    policyFlags: PolicyFlagTyped[];
    existingCoverages: ExistingCoverageTyped[];
}

export interface QuoteEnsWithRocTyped
    extends Omit<
        QuoteEnsWithRoc,
        'quoteEnsFlowFormValue' | 'calculateRateEnsOnlyResponse' | 'rocId' | 'rigs'
    > {
    rigs: RigWithModificationsTyped[];
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
}

export interface QuoteEnsWithPolicyEnsTyped
    extends Omit<
        QuoteEnsWithPolicyEns,
        | 'quoteEnsFlowFormValue'
        | 'calculateRateEnsOnlyResponse'
        | 'quoteEnsDocuments'
        | 'excludedCache'
        | 'rigs'
        | 'user'
        | 'policyEns'
    > {
    rigs: RigWithModificationsTyped[];
    quoteKind: QuotePolicyKind;
    quoteStatus: QuoteStatus;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
    calculateRateEnsOnlyResponse: CalculateRateEnsOnlyResponse;
    quoteEnsDocuments: QuoteEnsGeneratedDocuments;
    excludedCache: QuoteEnsExcludedCache[] | null;
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    policyEns: PolicyEnsTyped | null;
    user: UserForBaseTyped;
}

export interface EnsGetResponse extends EnsResponse {
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
}
