import type { ExistingCoveragePullStatus } from './coverage-verification';
import type {
    DriverLicenseStatus,
    DriverMaritalStatus,
    DriverTypedForQuote,
    DriverYearsLicensed,
} from './driver';
import type { QuoteEnsExclusion } from './exclusions';
import type { CoverageReportProvider } from './existing-coverage';
import type { CurrentInsurance } from './insurance-carriers';
import type { PaymentPlanName } from './payment-plan';
import type { QuotePolicyKind } from './quote-ens';
import type { VehicleType } from './rig';
import type {
    AcpeDeductible,
    CampsiteLiabilityLimit,
    EmergencyExpenseLimit,
    ENSTermRateKey,
    OffRoadRecoveryLimit,
    RocRigOwnedTenure,
    RocUnitClass,
    ScheduledMedicalBenefitLimit,
    TowRoadsideLimit,
    UnitCollDeductible,
    UnitCompDeductible,
} from './roc-lib';
import type { UtmLocalStorage } from './utm';

export interface QuoteEnsFlowFormValue {
    rigSets: QuoteRigSetFormValue[];
    gearCoverage: boolean;
    gearDetails: EnsGearDetailsFormValue | null;
    homeBase: HomeBaseFormValue;
    ensPersonalInfo: EnsPersonalInfoFormValue;
    specialCoverages: EnsSpecialCoveragesFormValue;
    pledge: boolean;
    term: ENSTermRateKey;
    timeZone: string;
    documentsAcknowledged: boolean;
    autoBillingOptIn: boolean | null;
    electronicDocuments: boolean;
    effectiveDate: string | null;
    quotePolicyKind: QuotePolicyKind | null;
    fromPolicyId: string | null;
    eSignatureConsentGiven: ESignatureConsent | null;
    ipAddress: string | null;
    selectedPaymentPlan: PaymentPlanName | null;
    rc2: RateCallTwoFormValue | null;
    organizationId: UUID | null; // null means tredder organization
    agentId: UUID | null; // agent userId
    existingCoveragePullStatus: ExistingCoveragePullStatus;
    existingCoverageManual: ExistingCoverageManualFormValue | null;
    utm: UtmLocalStorage | null;
    quotePrincipalDriver: DriverTypedForQuote | null;
    quoteAdditionalDrivers: DriverTypedForQuote[] | null;
    // otherDrivers: OtherDriver[] | null;
}

export interface QuoteEnsFlowFormValueCompleted extends QuoteEnsFlowFormValue {
    effectiveDate: string;
    eSignatureConsentGiven: ESignatureConsent;
    selectedPaymentPlan: PaymentPlanName;
    rc2: RateCallTwoFormValue;
}

export interface RateCallTwoFormValue {
    licenseStatus: DriverLicenseStatus;
    yearsLicensed: DriverYearsLicensed;
    currentInsurance: CurrentInsurance;
    numberOfResidents: number;
    overTwoViolations: boolean;
    overTwelveLiftKit: boolean;
}

export enum OtherDriverRelationship {
    spouse = 'spouse',
    child = 'child',
    sibling = 'sibling',
    parent = 'parent',
    grandparent = 'grandparent',
    friend = 'friend',
}

export enum OtherDriverStatus {
    included = 'included',
    excluded = 'excluded',
}

export interface OtherDriver {
    firstName: string;
    lastName: string;
    birthday: string;
    maritalStatus: DriverMaritalStatus;
    relationship: OtherDriverRelationship;
    status: OtherDriverRelationship;
}

export interface ESignatureConsent {
    date: string;
}

export interface QuoteRigSetFormValue {
    modsDetails: QrsModsDetailsFormValue | null;
    camperDetails: QrsUnitDetailsFormValue | null;
    trailerDetails: QrsUnitDetailsFormValue | null;
    rigName: string;
    vehicleType: VehicleType;
    rigDetails: QrsRigDetailsFormValue;
    coverageSelect: QuoteRigSetRigCoverageSelectFormValue;
    rigId: UUID | null;
}

export interface QuoteRigSetFormValueWithTrailer extends QuoteRigSetFormValue {
    trailerDetails: QrsUnitDetailsFormValue;
}

export interface QuoteRigSetFormValueWithCamper extends QuoteRigSetFormValue {
    camperDetails: QrsUnitDetailsFormValue;
}

export interface QuoteRigSetFormValueWithMods extends QuoteRigSetFormValue {
    modsDetails: QrsModsDetailsFormValue;
}

export enum RigStorageMethod {
    garage = 'garage',
    driveway = 'driveway',
    street = 'street',
}

export const rigStorageMethodText: EnumMap<RigStorageMethod> = {
    garage: 'Garage',
    driveway: 'Driveway',
    street: 'Street',
};

export interface QuoteRigSetCombinedCoverageSelectFormValue {
    modsCoverage: boolean;
    camperCoverage: boolean;
    trailerCoverage: boolean;
    gearCoverage: boolean;
}

export type QuoteRigSetRigCoverageSelectFormValue = {
    [index in CoverageSelectKey]: boolean;
};

export enum CoverageSelectKey {
    modsCoverage = 'modsCoverage',
    camperCoverage = 'camperCoverage',
    trailerCoverage = 'trailerCoverage',
}

export interface QuoteRigSetGearCoverageSelectFormValue {
    gearCoverage: boolean;
}

export interface QrsRigDetailsFormValue {
    year: number;
    make: string;
    model: string;
    trim: string;
    yearsOwned: RocRigOwnedTenure;
    ownOrLease: OwnOrLease;
    vehicleUse: VehicleUse;
    storageMethod: RigStorageMethod;
    vin: string | null;
}

export interface QrsUnitDetailsFormValue {
    unitValue: number;
    unitLength: number;
    unitAge: number;
    unitOwnedTenure: number;
    unitMake: string;
    unitModel: string;
    unitClass: RocUnitClass;
    unitCompDeductible: UnitCompDeductible;
    unitCollDeductible: UnitCollDeductible;
}

export interface HomeBaseFormValue {
    street: string;
    zip: string;
    city: string;
    state: string;
    country: string;
}

export interface QrsModsDetailsFormValue {
    modsValue: number;
    modsDeductible: AcpeDeductible;
}

export interface EnsGearDetailsFormValue {
    gearValue: number;
    gearCoverageType: GearCoverageType;
}

export interface ExistingCoverageManualFormValue {
    carrier: CurrentInsurance;
    carrierPolicyId: string;
    comprehensiveLimit: number;
    comprehensiveDeductible: number;
    collisionLimit: number;
    collisionDeductible: number;
    bodilyInjuryLimit: number;
    bodilyInjuryDeductible: number;
    startDate: string;
    endDate: string;
    isAcceptable: boolean;
    decPageContents: null;
    filePath: '';
    reportId: 'MANUAL';
    reportPolicyId: 'MANUAL';
    reportProvider: CoverageReportProvider.manual;
}

export interface EnsPersonalInfoFormValue {
    firstName: string;
    lastName: string;
    birthday: string;
    email: string;
    phone: string; //string, but should be exactly 10 numbers eg. "8607452469"
    tcpaAgree: boolean;
    ssn: string | null;
    // driversLicenseNumber: string | null;
    // driversLicenseState: State | null;
    maritalStatus: DriverMaritalStatus;
}

export interface EnsSpecialCoveragesFormValue {
    campsiteLiability: CampsiteLiabilityOptions;
    emergencyExpense: EmergencyExpenseOptions;
    offRoadRecovery: OffRoadRecoveryOptions;
    scheduledMedicalBenefit: ScheduledMedicalBenefitOptions;
    towRoadside: TowingAndRoadsideOptions;
}

export interface CampsiteLiabilityOptions {
    selected: boolean;
    campsiteLiabilityLimit: CampsiteLiabilityLimit;
}

export interface EmergencyExpenseOptions {
    selected: boolean;
    emergencyExpenseLimit: EmergencyExpenseLimit;
}

export interface OffRoadRecoveryOptions {
    selected: boolean;
    offRoadRecoveryLimit: OffRoadRecoveryLimit;
}

export interface ScheduledMedicalBenefitOptions {
    selected: boolean;
    scheduledMedicalBenefitLimit: ScheduledMedicalBenefitLimit;
}

export interface TowingAndRoadsideOptions {
    selected: boolean;
    towRoadsideLimit: TowRoadsideLimit;
}

export interface RigCoverageAdjustmentsFormValue {
    modsValue: number | null;
    modsDeductible: string | null;
    camperValue: number | null;
    camperCompDeductible: string | null;
    camperCollDeductible: string | null;
    trailerValue: number | null;
    trailerCompDeductible: string | null;
    trailerCollDeductible: string | null;
}

export interface OverlandGearAdjustmentsFormValue {
    gearValue: number | null;
    coverageType: GearCoverageType | null;
}

export enum GearCoverageType {
    actualCashValue = 'actualCashValue',
    replacementCost = 'replacementCost',
}

export const gearCoverageTypeText: EnumMap<GearCoverageType> = {
    actualCashValue: 'Actual Cash Value',
    replacementCost: 'Replacement Cost',
};

export interface SpecialCoverageAdjustmentsFormValue {
    campsiteLiabilityEnabled: boolean | null;
    campsiteLiabilityLimit: CampsiteLiabilityLimit | null;
    emergencyExpenseEnabled: boolean | null;
    emergencyExpenseLimit: EmergencyExpenseLimit | null;
    offRoadRecoveryEnabled: boolean | null;
    // offRoadRecoveryLimit: OffRoadRecoveryLimit | null;
    scheduledMedicalBenefitEnabled: boolean | null;
    // scheduledMedicalBenefitLimit: ScheduledMedicalBenefitLimit | null;
    towRoadsideEnabled: boolean | null;
    towRoadsideLimit: TowRoadsideLimit | null;
}

export interface QuoteEnsExcludedCache {
    exclusion: QuoteEnsExclusion;
    quoteEnsFlowFormValue: QuoteEnsFlowFormValue;
}

export enum OwnOrLease {
    finance = 'finance',
    own = 'own',
    lease = 'lease',
}

export const ownOrLeaseText: EnumMap<OwnOrLease> = {
    finance: 'Finance',
    own: 'Own',
    lease: 'Lease',
};

export enum VehicleUse {
    commute = 'commute',
    pleasure = 'pleasure',
    dailyDriver = 'dailyDriver',
}

export const vehicleUseText: EnumMap<VehicleUse> = {
    pleasure: 'Pleasure (recreational)',
    commute: 'Commute (to/from school work)',
    dailyDriver: 'Daily Driver (no commuting)',
};
