import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    ConfirmEmailParams,
    CreatePasswordPayload,
    CreatePasswordResponse,
    IsNewUserPayload,
    IsNewUserResponse,
    ResetPasswordCompletePayload,
    ResetPasswordInitiatePayload,
} from '@backend-types/auth';
import {
    LoginPayload,
    RegisterPayload,
    TokenResponse,
    UserForTokenTyped,
} from '@backend-types/authorization';
import {
    ActiveAuthUser,
    AuthUtilsService,
    EnvService,
    OverlayService,
    UserService,
    UtilityService,
} from '@common/services';
import { combineLatest, from, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private authUtilsService: AuthUtilsService,
        private utilityService: UtilityService,
        private userService: UserService,
        private router: Router,
        private overlayService: OverlayService
    ) {}

    login$(loginPayload: LoginPayload): Observable<boolean> {
        return this.http
            .post<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/login`,
                loginPayload
            )
            .pipe(
                switchMap(
                    (tokenResponse): Observable<ActiveAuthUser> =>
                        this.authUtilsService.processToken$(tokenResponse.token)
                ),
                switchMap((user) => {
                    return this.authUtilsService.navigateAfterAuth(user, '/auth/login', true);
                }),
                catchError((error: Error) => throwError(() => error))
            );
    }

    register$(registerPayload: RegisterPayload): Observable<boolean> {
        return this.http
            .post<void>(
                `${this.envService.config.backendURL}/api/latest/auth/register`,
                registerPayload
            )
            .pipe(
                switchMap(
                    (): Observable<boolean> =>
                        from(this.router.navigate(['/auth/awaiting-confirmation']))
                ),
                catchError((error: Error) => throwError(() => error))
            );
    }

    confirmEmail$(confirmEmailParams: ConfirmEmailParams): Observable<boolean> {
        return this.http
            .get<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/confirm-email/${confirmEmailParams.token}`
            )
            .pipe(
                switchMap((tokenResponse): Observable<[UserForTokenTyped, TokenResponse]> => {
                    const user = this.authUtilsService.processToken$(tokenResponse.token);
                    return combineLatest([user, of(tokenResponse)]);
                }),
                switchMap(([user, tokenResponse]) => {
                    if (tokenResponse.passwordChangeRequired) {
                        this.authUtilsService.redirectURL = {
                            url: `/auth/password-reset/${user.id}/${tokenResponse.passwordResetToken}`,
                        };
                    }
                    return this.authUtilsService.navigateAfterAuth(user, '/auth/confirm-email');
                }),
                catchError((error: Error) => throwError(() => error))
            );
    }

    resetPasswordInitiate$(
        resetPasswordInitiatePayload: ResetPasswordInitiatePayload
    ): Observable<void> {
        return this.http.post<void>(
            `${this.envService.config.backendURL}/api/latest/auth/reset-password-initiate`,
            resetPasswordInitiatePayload
        );
    }

    resetPasswordComplete$(
        resetPasswordCompletePayload: ResetPasswordCompletePayload
    ): Observable<boolean> {
        return this.http
            .post<TokenResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/reset-password-complete`,
                resetPasswordCompletePayload
            )
            .pipe(
                switchMap(
                    (tokenResponse): Observable<ActiveAuthUser> =>
                        this.authUtilsService.processToken$(tokenResponse.token)
                ),
                switchMap((user) => {
                    return this.authUtilsService.navigateAfterAuth(user, '/auth/login');
                }),
                catchError((error: Error) => throwError(() => error))
            );
    }

    isNewUser$(isNewIserPayload: IsNewUserPayload): Observable<IsNewUserResponse> {
        return this.http.post<IsNewUserResponse>(
            `${this.envService.config.backendURL}/api/latest/auth/is-new-user`,
            isNewIserPayload
        );
    }

    createPassword$(createPasswordPayload: CreatePasswordPayload): Observable<boolean> {
        this.overlayService.show('Creating Password');
        return this.http
            .post<CreatePasswordResponse>(
                `${this.envService.config.backendURL}/api/latest/auth/create-password`,
                createPasswordPayload
            )
            .pipe(
                switchMap(
                    (tokenResponse): Observable<UserForTokenTyped> =>
                        this.authUtilsService.processToken$(tokenResponse.token)
                ),
                switchMap((user) => {
                    return from(this.router.navigate(['/account/insurance/dashboard']));
                }),
                catchError((error: Error) => throwError(() => error)),
                finalize(() => {
                    this.overlayService.hide();
                })
            );
    }
}
