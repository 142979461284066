import { Pipe, PipeTransform } from '@angular/core';
import { PolicyEnsForFormatTyped } from '@backend-types/policy-ens';

@Pipe({
    name: 'policyNumber',
})
export class PolicyNumberPipe implements PipeTransform {
    constructor() {}
    transform(policyEns: PolicyEnsForFormatTyped) {
        return `${_sliceEntityNumberForPolicy(policyEns.policyWrapper.policyNumber)}-${policyEns.iteration.toString().padStart(2, '0')}`;
    }
}

@Pipe({
    name: 'policyNumberOnly',
})
export class PolicyNumberOnlyPipe implements PipeTransform {
    constructor() {}
    transform(policyNumber: number) {
        return `${_sliceEntityNumberForPolicy(policyNumber)}`;
    }
}

@Pipe({
    name: 'quoteEnsNumber',
})
export class QuoteEnsNumberPipe implements PipeTransform {
    constructor() {}
    transform(quoteNumber: number) {
        return `Q-ENS-${_sliceEntityNumber(quoteNumber)}`;
    }
}

@Pipe({
    name: 'invoiceNumber',
})
export class InvoiceNumberPipe implements PipeTransform {
    constructor() {}
    transform(invoiceNumber: number) {
        return `INV-${_sliceEntityNumber(invoiceNumber)}`;
    }
}

@Pipe({
    name: 'receiptNumber',
})
export class ReceiptNumberPipe implements PipeTransform {
    constructor() {}
    transform(receiptNumber: number) {
        return `RCT-${_sliceEntityNumber(receiptNumber)}`;
    }
}

function _sliceEntityNumberForPolicy(entityNumber: number) {
    const entityNumberString = entityNumber.toString();

    const c = entityNumberString.slice(4, 7);
    const d = entityNumberString.slice(7, 10);

    return `${c}-${d}`;
}

function _sliceEntityNumber(entityNumber: number) {
    const entityNumberString = entityNumber.toString();

    const a = entityNumberString.slice(0, 1);
    const b = entityNumberString.slice(1, 4);
    const c = entityNumberString.slice(4, 7);
    const d = entityNumberString.slice(7, 10);

    return `${a}-${b}-${c}-${d}`;
}
