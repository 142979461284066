import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { EnsGearDetailsFormValue, GearCoverageType } from '@backend-types/quote-flow-ens';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { valueLimits } from '@data/value-limits.data';
import { RoundingService } from '@modules/quote/services';
import { debounceTime, Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-gear-details-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-gear-details-form.component.html',
    styleUrls: ['quote-gear-details-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteGearDetailsFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteGearDetailsFormComponent,
        },
    ],
})
export class QuoteGearDetailsFormComponent
    implements OnInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    valueLimits = valueLimits;
    gearValueRounded = false;

    // activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    gearDetailsForm: ModelFormGroup<EnsGearDetailsFormValue> = this.fb.group({
        gearValue: new FormControl<number | null>(null, [
            Validators.min(valueLimits.gear.min),
            Validators.max(valueLimits.gear.max),
        ]),
        gearCoverageType: new FormControl<GearCoverageType | null>(
            GearCoverageType.actualCashValue,
            [Validators.required]
        ),
    });

    onTouched: () => unknown = () => {};
    onChange = (rigDetails: ModelFormValue<EnsGearDetailsFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private roundingService: RoundingService,
        private changeDetectorRef: ChangeDetectorRef,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Gear Details');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_gear_details',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.gearDetailsForm.patchValue({
                    gearValue: 10000,
                });
                this.changeDetectorRef.detectChanges();
            })
        );

        this.subscription.add(
            this.gearValueControl.valueChanges.pipe(debounceTime(600)).subscribe((gearValue) => {
                this.gearValueRounded =
                    this.roundingService.roundInputValue({
                        inputValue: gearValue,
                        inputValueControl: this.gearValueControl,
                        minValue: valueLimits.gear.min,
                        roudedTo: valueLimits.gear.roundedTo,
                    }) || this.gearValueRounded;

                this.changeDetectorRef.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (rigDetails: ModelFormValue<EnsGearDetailsFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.gearDetailsForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.gearDetailsForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const rigDetails = this._gearDetailsFormValue();
                        if (this.gearValueControlValid) {
                            onChange(rigDetails);
                        }
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.gearDetailsForm.disable();
        } else {
            this.gearDetailsForm.enable();
        }
    }

    writeValue(value: EnsGearDetailsFormValue | null) {
        const gearDetailsFormDefaultValues: ModelFormValue<EnsGearDetailsFormValue> = {
            gearValue: null,
            gearCoverageType: GearCoverageType.actualCashValue,
        };

        if (value === null) {
            this.gearDetailsForm.reset(gearDetailsFormDefaultValues);
        }
        if (value) {
            this.gearDetailsForm.setValue(value, { emitEvent: false });
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.gearDetailsForm?.invalid) {
            return { gearDetailsFormInvalid: true };
        }

        return null;
    }

    private _gearDetailsFormValue(): ModelFormValue<EnsGearDetailsFormValue> {
        const { gearValue, gearCoverageType } = this.gearDetailsForm.value;

        this.assertionService.isDefinedOrThrow(gearValue);
        this.assertionService.isDefinedOrThrow(gearCoverageType);

        return {
            gearValue,
            gearCoverageType,
        };
    }

    /* Accessor Methods */

    get gearValueControl() {
        return this.gearDetailsForm.get('gearValue') as FormControl;
    }

    get gearValueControlValid() {
        return (
            this.gearValueControl.value &&
            !(
                this.gearValueControl.hasError('required') ||
                this.gearValueControl.hasError('min') ||
                this.gearValueControl.hasError('max')
            )
        );
    }

    get gearValueControlInvalid() {
        return (
            (this.gearValueControl.touched ||
                this.assertionService.isDefined(this.gearValueControl.value)) &&
            (this.gearValueControl.hasError('required') ||
                this.gearValueControl.hasError('min') ||
                this.gearValueControl.hasError('max'))
        );
    }

    get allValid() {
        return this.gearValueControlValid;
    }
}
