import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    EmailQuoteResponse,
    EnsByIdResponse,
    EnsPayload,
    EnsResponse,
    EnsUpdatePayload,
} from '@backend-types/quote-ens';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import { EnvService, OverlayService } from '@common/services';
import { ExistingPolicyModalComponent } from '@modules/quote/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, Observable, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class QuoteService {
    constructor(
        private http: HttpClient,
        private envService: EnvService,
        private ngbModalService: NgbModal,
        private overlayService: OverlayService
    ) {}

    emailQuote$(quoteId: UUID): Observable<{}> {
        return this.http.get<EmailQuoteResponse>(
            `${this.envService.config.backendURL}/api/latest/quote/email-quote/${quoteId}`
        );
    }

    ensQuote$(ensPayload: EnsPayload): Observable<EnsResponse> {
        return this.http
            .post<EnsResponse | null>(
                `${this.envService.config.backendURL}/api/latest/quote/ens`,
                ensPayload
            )
            .pipe(
                tap((ensResponse) => {
                    if (ensResponse === null) {
                        this.overlayService.hide();
                        this.ngbModalService.open(ExistingPolicyModalComponent, {
                            backdrop: 'static',
                            keyboard: false,
                            size: 'md',
                        });
                    }
                }),
                filter(inputIsNotNullOrUndefined)
            );
        // .pipe(
        //     tap((ensResponse) => {
        //         const exclusion = ensResponse.exclusion;
        //         if (exclusion) {
        //             this.router.navigate([`/quote/sorry`], {
        //                 queryParams: { reason: quoteEnsExclusionConfigs[exclusion].queryParam },
        //             });
        //         }
        //     }),
        //     filter(inputIsEnsResponseWithEnsOnly)
        // );
    }

    ensQuoteUpdate$(ensUpdatePayload: EnsUpdatePayload): Observable<EnsResponse> {
        return this.http.put<EnsResponse>(
            `${this.envService.config.backendURL}/api/latest/quote/ens`,
            ensUpdatePayload
        );
        // .pipe(
        //     map((ensResponse) => {
        //         const exclusion = ensResponse.exclusion;
        //         if (exclusion) {
        //             this.router.navigate([`/quote/sorry`], {
        //                 queryParams: { reason: quoteEnsExclusionConfigs[exclusion].queryParam },
        //             });
        //             // throw new Error('ENS_RESPONSE_CONTAINS_EXCLUSION');
        //         }
        //         return ensResponse;
        //     }),
        //     filter(inputIsEnsResponseWithEnsOnly)
        // );
    }

    ensQuoteById$(id: UUID) {
        return this.http.get<EnsByIdResponse>(
            `${this.envService.config.backendURL}/api/latest/quote/ens-by-id/${id}`
        );
    }
}
