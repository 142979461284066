import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import {
    PaymentIntentKind,
    PaymentReason,
    paymentReasonDescriptionMap,
} from '@backend-types/payment';
import { PaymentPlanName, PaymentPlanSource } from '@backend-types/payment-plan';
import { EnsResponseWithEnsOnly } from '@backend-types/quote-ens';
import { QuoteEnsFlowFormValue } from '@backend-types/quote-flow-ens';
import { inputIsNotNullOrUndefined } from '@common/helpers';
import {
    AnalyticsService,
    HotKeysService,
    NavigateOptions,
    OverlayService,
    StripeService,
} from '@common/services';
import { AgencyAdminAccount } from '@modules/manage-common/models';
import {
    PaymentPlanService,
    QuoteEnsFormService,
    QuoteEnsRetrievalService,
    QuoteSharedService,
} from '@modules/quote/services';
import { StripeError } from '@stripe/stripe-js';
import Big from 'big.js';
import { format } from 'date-fns';
import { combineLatest, filter, ReplaySubject, Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-payment',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-payment.component.html',
    styleUrls: ['quote-ens-payment.component.scss'],
})
export class QuoteEnsPaymentComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() endorsement = false;
    @Input() agencyAdminAccount = AgencyAdminAccount.account;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    activeEnsQuote!: EnsResponseWithEnsOnly;
    quoteEnsFlowFormValue!: QuoteEnsFlowFormValue;
    paymentPlanSource!: PaymentPlanSource;
    invoiceDescription = paymentReasonDescriptionMap.newPolicy;
    invoiceDescription2!: string;

    stripeError!: StripeError;

    ePaymentPlanName = PaymentPlanName;

    paymentFormComplete = false;

    subscription: Subscription = new Subscription();

    okayToInitStripePaymentElement$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    constructor(
        private hotKeysService: HotKeysService,
        private route: ActivatedRoute,
        private quoteEnsRetrievalService: QuoteEnsRetrievalService,
        private quoteSharedService: QuoteSharedService,
        private paymentPlanService: PaymentPlanService,
        private stripeService: StripeService,
        private overlayService: OverlayService,
        private changeDetectorRef: ChangeDetectorRef,
        private quoteEnsFormService: QuoteEnsFormService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - Payment');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_payment',
            label: this.agencyAdminAccount,
            category: this.endorsement ? 'endorsement' : undefined,
        });
    }

    ngOnInit() {
        this.overlayService.show('Preparing Payment...');
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );

        this.subscription.add(
            combineLatest([
                this.route.queryParamMap,
                this.quoteEnsRetrievalService.activeEnsQuote$.pipe(
                    filter(inputIsNotNullOrUndefined)
                ),
            ])
                .pipe(
                    tap(([queryParamMap, activeEnsQuote]) => {
                        const paymentPlanName = this.quoteSharedService.queryParamToPaymentPlanName(
                            queryParamMap.get('plan')
                        );

                        if (!paymentPlanName) {
                            throw new Error('PAYMENT_PLAN_NAME_NOT_FOUND');
                        }

                        this.activeEnsQuote = activeEnsQuote;

                        this.paymentPlanSource = this.paymentPlanService.getPaymentPlanSource(
                            activeEnsQuote.ensOnly,
                            paymentPlanName,
                            this.endorsement
                        );
                        if (this.paymentPlanSource.name === PaymentPlanName.full) {
                            const effectiveDate = format(
                                new Date(this.paymentPlanSource.effectiveDate),
                                `MM/dd/yyyy`
                            );
                            const expirationDate = format(
                                new Date(this.paymentPlanSource.expirationDate),
                                `MM/dd/yyyy`
                            );
                            this.invoiceDescription2 = `Paid in Full ${effectiveDate} - ${expirationDate}`;
                        }

                        if (this.paymentPlanSource.name === PaymentPlanName.twentySixteen) {
                            // const effectiveDate = format(
                            //     new Date(this.paymentPlanSource.effectiveDate),
                            //     `MM/dd/yyyy`
                            // );
                            // const expirationDate = format(
                            //     new Date(this.paymentPlanSource.installments[1].dueDate),
                            //     `MM/dd/yyyy`
                            // );
                            const numberOfPayments = this.paymentPlanSource.installments.length;
                            this.invoiceDescription2 = `Payment  1 of ${numberOfPayments}`;
                        }

                        this.changeDetectorRef.detectChanges();
                    })
                )
                .subscribe(() => this.okayToInitStripePaymentElement$.next(true))
        );
        this.subscription.add(
            this.stripeService.paymentElementReady$.subscribe(() => {
                this.overlayService.hide();
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.stripeService.paymentElementChange$.subscribe((paymentElementChangeEvent) => {
                this.paymentFormComplete = paymentElementChangeEvent.complete;
                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.quoteEnsFormService.quoteEnsFlowFormValue$.subscribe(
                (quoteEnsFlowFormValue) =>
                    (this.quoteEnsFlowFormValue = quoteEnsFlowFormValue as QuoteEnsFlowFormValue)
            )
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit() {
        this.subscription.add(
            this.okayToInitStripePaymentElement$.subscribe(() => {
                this.stripeService
                    .paymentElementInit$(
                        {
                            kind: PaymentIntentKind.ensFirstInstallment,
                            data: {
                                quoteEnsId: this.activeEnsQuote.quoteEnsId,
                                paymentPlanName: this.paymentPlanSource.name,
                                paymentReason: this.endorsement
                                    ? PaymentReason.endorsement
                                    : PaymentReason.newPolicy,
                            },
                        },
                        {},
                        {
                            defaultValues: {
                                billingDetails: {
                                    name: `${this.quoteEnsFlowFormValue.ensPersonalInfo.firstName} ${this.quoteEnsFlowFormValue.ensPersonalInfo.lastName}`,
                                    email: this.quoteEnsFlowFormValue.ensPersonalInfo.email,
                                },
                            },
                        }
                    )
                    .subscribe(() => this.changeDetectorRef.detectChanges());
            })
        );
    }

    processPayment() {
        if (!this.paymentFormComplete) {
            return;
        }

        let returnPath: string = this._returnPath();

        const errorPath: NavigateOptions = this._errorPath();

        this.subscribeToConfirmPayment(returnPath, errorPath);
    }

    private _returnPath() {
        let returnPath: string;

        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    returnPath = '/agency/policies/endorsement/payment-confirmed';
                } else {
                    returnPath = '/agency/quotes/payment-confirmed';
                }
                break;
            case AgencyAdminAccount.admin:
                returnPath = '/admin/policies-ens/endorsement/payment-confirmed';
                break;
            case AgencyAdminAccount.account:
                if (this.endorsement) {
                    returnPath = '/account/insurance/endorsement/payment-confirmed';
                } else {
                    returnPath = '/quote/ens?quote-ens-form-step=payment-confirmed';
                }
                break;
            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
        return returnPath;
    }

    subscribeToConfirmPayment(returnPath: string, errorPath: NavigateOptions) {
        this.subscription.add(
            this.stripeService
                .confirmPayment$({
                    returnPath,
                    errorPath,
                })
                .subscribe((confirmationResponse) => {
                    if (confirmationResponse.error) {
                        this.stripeError = confirmationResponse.error;
                        this.changeDetectorRef.detectChanges();
                        this.analyticsService.sendEventCustom({
                            action: 'quote_ens_payment_error',
                            value: Big(this.activeEnsQuote.ensOnly.totals.total).toNumber(),
                            label: this.agencyAdminAccount,
                            category: this.endorsement ? 'endorsement' : undefined,
                        });
                    } else {
                        this.analyticsService.sendEventCustom({
                            action: 'quote_ens_payment_confirmed',
                            value: Big(this.activeEnsQuote.ensOnly.totals.total).toNumber(),
                            label: this.agencyAdminAccount,
                            category: this.endorsement ? 'endorsement' : undefined,
                        });
                    }
                })
        );
    }

    _errorPath(): NavigateOptions {
        switch (this.agencyAdminAccount) {
            case AgencyAdminAccount.agency:
                if (this.endorsement) {
                    return {
                        path: `/agency/policies/endorsement/payment-error`,
                    };
                } else {
                    return {
                        path: `/agency/quotes/payment-error`,
                    };
                }
            case AgencyAdminAccount.admin:
                return {
                    path: `/admin/policies-ens/endorsement/payment-error`,
                };
            case AgencyAdminAccount.account:
                if (this.endorsement) {
                    return {
                        path: `/account/insurance/endorsement/payment-error`,
                    };
                } else {
                    return {
                        path: '/quote/ens',
                        navigationExtras: {
                            queryParamsHandling: 'merge',
                            queryParams: {
                                'quote-ens-form-step': `payment-error`,
                            },
                        },
                    };
                }
            default:
                throw new Error(`AGENCY_ADMIN_ACCOUNT_NOT_RECOGNIZED: ${this.agencyAdminAccount}`);
        }
    }
}
