import { ParamMap } from '@angular/router';
import { throwError } from 'rxjs';

export const getParam = <T>(paramMap: ParamMap, param: string): T => {
    const paramMapRigID = paramMap.get(param);
    if (!paramMapRigID) {
        throwError(() => new Error(`PARAM_NOT_FOUND: ${param}`));
    }
    return paramMapRigID as T;
};
