import { DynamicRouteData } from '@modules/navigation/models';

import { sharedMetaTags } from './_shared-meta-tags';

export const rig: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigBuild: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig Builder',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigView: DynamicRouteData = {
    isPublic: true,
    title: 'Tredder Rig Viewer',
    metaTags: {
        ...sharedMetaTags,
    },
};

export const rigConfigs = {
    rig,
    rigBuild,
    rigView,
};
