import { Pipe, PipeTransform } from '@angular/core';
import { ExistingCoveragePullStatus } from '@backend-types/coverage-verification';
import { InvoiceStatus } from '@backend-types/payment-plan';
import { PolicyStatus } from '@backend-types/policy-ens';
import { QuoteStatus } from '@backend-types/quote-ens';

@Pipe({
    name: 'invoiceStatus',
})
export class InvoiceStatusClass implements PipeTransform {
    constructor() {}
    // eslint-disable-next-line complexity
    transform(invoiceStatus: InvoiceStatus) {
        switch (invoiceStatus) {
            case InvoiceStatus.paid:
                return 'bg-success text-white';
            case InvoiceStatus.due:
                return 'bg-primary-alt text-white';
            case InvoiceStatus.notDue:
            case InvoiceStatus.endorsed:
                return 'bg-primary text-white';
            case InvoiceStatus.overDue:
                return 'bg-warning text-primary';
            case InvoiceStatus.canceled:
            case InvoiceStatus.paymentFailure:
                return 'bg-danger text-white';
            default:
                break;
        }
    }
}

@Pipe({
    name: 'policyStatus',
})
export class PolicyStatusClass implements PipeTransform {
    constructor() {}
    // eslint-disable-next-line complexity
    transform(policyStatus: PolicyStatus) {
        switch (policyStatus) {
            case PolicyStatus.none:
                return 'bg-light text-primary';
            case PolicyStatus.active:
                return 'bg-success text-white';
            case PolicyStatus.lapsed:
                return 'bg-warning text-primary';
            case PolicyStatus.canceled:
                return 'bg-danger text-white';
            case PolicyStatus.renewed:
            case PolicyStatus.endorsed:
                return 'bg-secondary text-white';
            default:
                break;
        }
    }
}

@Pipe({
    name: 'quoteStatus',
})
export class QuoteStatusClass implements PipeTransform {
    constructor() {}
    // eslint-disable-next-line complexity
    transform(quoteStatus: QuoteStatus) {
        switch (quoteStatus) {
            case QuoteStatus.converted:
                return 'bg-success text-white';
            case QuoteStatus.active:
                return 'bg-warning text-primary';
            case QuoteStatus.expired:
                return 'bg-danger text-white';
            default:
                break;
        }
    }
}

@Pipe({
    name: 'pullStatus',
})
export class ExistingCoveragePullStatusClass implements PipeTransform {
    constructor() {}
    // eslint-disable-next-line complexity
    transform(pullStatus: ExistingCoveragePullStatus) {
        switch (pullStatus) {
            case ExistingCoveragePullStatus.pulled:
                return 'bg-success text-white';
            case ExistingCoveragePullStatus.pulling:
                return 'bg-light text-primary';
            case ExistingCoveragePullStatus.notPulled:
                return 'bg-warning text-primary';
            case ExistingCoveragePullStatus.error:
            case ExistingCoveragePullStatus.expired:
                return 'bg-danger text-white';
            case ExistingCoveragePullStatus.notRequired:
                return 'bg-primary-alt text-white';
            default:
                break;
        }
    }
}
