<div class="container-xxl" *ngIf="!formOnly">
    <div class="text-center">
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3">
            <div class="d-md-none d-block">
                <div class="quote-title">Let's give your</div>
                <div class="quote-title">rig a name</div>
            </div>
            <div class="d-md-block d-none"><div class="quote-title">Let's give your rig a name</div></div>
        </div>
        <div class="text-start text-md-center ms-3 ms-sm-5 ms-md-0 mb-md-5 mb-3"><div class="quote-subtitle">We know you’ve put a lot of thought into your rig now we want to know it’s name!</div></div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-md-6 col-12"><ng-template [ngTemplateOutlet]="rigNameFormTemplate"></ng-template></div>
        </div>
        <button class="btn btn-xl rounded-pill btn-primary-gradient quote-next-button mb-md-5 mb-5 mt-md-5 mt-3" data-cy="quoteFlowRigNameNextButton" type="button" (click)="next.emit()" tabindex="0" [attr.disabled]="!rigNameControlValid ? true : null">
            <div class="d-flex align-items-center justify-content-center">Next</div>
            <sbf-tredder-icons name="arrow-right" height="18px"></sbf-tredder-icons>
        </button>
        <div class="row justify-content-center">
            <div class="col-xl-8 col-12">
                <div class="text-primary text-start text-sm mx-3 mb-3">By clicking "Next", you confirm that you have read the Information Disclosure below and the notices referenced, and would like to continue.</div>
                <div class="text-start mx-3">
                    <div class="text-primary mb-1">Information Disclosure</div>
                    <div class="text-sm mb-1">
                        To provide you with a more accurate quote, we obtain information about you and other drivers in your household from consumer reporting agencies and other third parties, where permitted by law. This may include a credit-based insurance score, driving and claim histories, and other consumer reports. We use this information to underwrite and rate your policy. We may order additional consumer reports to update or renew your insurance. Please read our
                        <div class="text-link text-cyan-blue primary-hover d-inline-block" data-cy="privacyNoticeLink" routerLink="/privacy-policy">Privacy Notice</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="formOnly"><ng-template [ngTemplateOutlet]="rigNameFormTemplate"></ng-template></ng-container
><ng-template #rigNameFormTemplate
    ><form data-cy="rigNameForm" [formGroup]="rigNameForm">
        <div class="form-floating">
            <input class="form-control" id="rigNameInput" data-cy="rigNameInput" autocomplete="off" type="text" aria-describedby="rigNameHelp" sbwAutoFocus formControlName="rigName" [class.is-invalid]="rigNameControlInvalid" placeholder="rigName" (keyup.enter)="rigNameControlValid &amp;&amp; next.emit()" />
            <div class="invalid-feedback" *ngIf='rigNameControl.hasError("required")'>Rig Name required.</div>
            <label class="text-gray-600 small" for="rigNameInput">Your Rig's Nickname</label>
        </div>
    </form></ng-template
>
