// https://www.digitalocean.com/community/tutorials/how-to-add-twitter-card-and-open-graph-social-metadata-to-your-webpage-with-html
// https://www.opengraph.xyz/url/https%3A%2F%2Ftredder.com

// https://developers.facebook.com/tools/debug/

import { MetaTagConfigs } from '@common/models';

const description = 'A new kind of insurance for the Adventure Driven';

const ogImage = 'https://tredder.com/assets/img/meta/tredder-og-fb.jpg';

export const sharedMetaTags: MetaTagConfigs = {
    description: {
        attribute: 'name',
        value: description,
    },
    ogDescription: {
        attribute: 'property',
        value: description,
    },
    ogImage: {
        attribute: 'property',
        value: ogImage,
    },
    ogImageAlt: {
        attribute: 'property',
        value: 'Tredder - Adventure Driven',
    },
    ogImageHeight: {
        attribute: 'property',
        value: '630',
    },
    ogImageSecureUrl: {
        attribute: 'property',
        value: ogImage,
    },
    ogImageWidth: {
        attribute: 'property',
        value: '1200',
    },
    ogLocale: {
        attribute: 'property',
        value: 'en_US',
    },
    ogSiteName: {
        attribute: 'property',
        value: 'Tredder',
    },
    ogTitle: {
        attribute: 'property',
        value: 'Tredder',
    },
    ogType: {
        attribute: 'property',
        value: 'website',
    },
    ogURL: {
        attribute: 'property',
        value: 'https://tredder.com/',
    },
    twitterCard: {
        attribute: 'name',
        value: 'summary_large_image',
    },
    twitterDomain: {
        attribute: 'property',
        value: 'tredder.com',
    },
    twitterUrl: {
        attribute: 'property',
        value: 'https://tredder.com',
    },
    twitterTitle: {
        attribute: 'name',
        value: 'Tredder',
    },
    twitterDescription: {
        attribute: 'name',
        value: description,
    },
    twitterImage: {
        attribute: 'name',
        value: 'https://tredder.com/assets/img/meta/tredder-og-twitter.jpg',
    },
    twitterSite: {
        attribute: 'name',
        value: '@gettredder',
    },
};
