<form data-cy="rigCoverageAdjustmentsForm" [formGroup]="rigCoverageAdjustmentsForm" *ngIf="quoteEnsFlowFormValue">
    <ng-container formArrayName="rigCoverageAdjustments"
        ><div class="tredder-card coverage-card mb-5" *ngFor="let rigCoverageAdjustment of rigCoverageAdjustmentsArray.controls; let i = index">
            <form [formGroup]="rigCoverageAdjustment">
                <div class="mb-3">
                    <div class="text-start">
                        <div class="d-flex justify-content-between align-items-center">
                            <h2>{{quoteEnsFlowFormValue.rigSets[i]?.rigName}}</h2>
                            <span
                                ><div class="text-sm text-cyan-blue pointer" *ngIf="!!collapsedRigs[i]" (click)="toggleCollapse(i)">expand</div>
                                <div class="text-sm text-cyan-blue pointer" *ngIf="!collapsedRigs[i]" (click)="toggleCollapse(i)">collapse</div></span
                            >
                        </div>
                        <div class="text-grey-blue">
                            {{quoteEnsFlowFormValue.rigSets[i]?.rigDetails.year}} &nbsp; &bull; &nbsp; {{quoteEnsFlowFormValue.rigSets[i]?.rigDetails.make}} &nbsp; &bull; &nbsp; {{quoteEnsFlowFormValue.rigSets[i]?.rigDetails.model}}
                            <div class="d-sm-inline-block d-none">&nbsp; &bull; &nbsp; {{quoteEnsFlowFormValue.rigSets[i]?.rigDetails.trim}}</div>
                        </div>
                    </div>
                </div>
                <div class="collapsible-section collapse" [ngClass]="{show: !collapsedRigs[i]}">
                    <hr class="full mb-3" *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.modsCoverage || !quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.camperCoverage || !quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.trailerCoverage" />
                    <div class="d-flex justify-content-evenly align-items-center flex-wrap">
                        <div class="text-link d-flex align-items-center ms-2 me-3 mb-3" [attr.data-cy]='"addModsButton-" + i' *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.modsCoverage" (click)="addMods(i)">
                            <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                            <div class="ms-2">Add mods</div>
                        </div>
                        <div class="text-link d-flex align-items-center ms-2 me-3 mb-3" [attr.data-cy]='"addCamperButton-" + i' *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.camperCoverage" (click)="addCamper(i)">
                            <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                            <div class="ms-2">Add camper</div>
                        </div>
                        <div class="text-link d-flex align-items-center ms-2 mb-3" [attr.data-cy]='"addTrailerButton-" + i' *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.trailerCoverage" (click)="addTrailer(i)">
                            <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                            <div class="ms-2">Add trailer</div>
                        </div>
                    </div>
                    <div class="mb-3" data-cy="modsCoverageCard" *ngIf="quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.modsCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">
                                Mods
                                <div class="d-sm-inline-block d-none">& Upgrades</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="text-link" [attr.data-cy]='"editModsButton-" + i' (click)="editMods(i)">edit</div>
                                <div class="mx-2">-</div>
                                <div class="text-link-danger" [attr.data-cy]='"removeModsButton-" + i' (click)="removeMods(i)">remove</div>
                            </div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_mods_full.svg" alt="Mods svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue" *ngIf="editValues"
                                ><div class="row justify-content-start">
                                    <div class="col-xl-4 col-lg-6 col-12">
                                        <div class="form-floating mb-3">
                                            <input class="form-control valid-override" id="modsValueInput" [attr.data-cy]='"modsValueInput-" + i' autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="modsValueHelp" formControlName="modsValue" [class.is-invalid]="rigCoverageAdjustment.controls.modsValue.invalid" [class.is-valid]="rigCoverageAdjustment.controls.modsValue.valid" placeholder="modsValue" prefix=" $" mask="separator.0" thousandSeparator="," />
                                            <div class="invalid-feedback" *ngIf='rigCoverageAdjustment.controls.modsValue.hasError("required")'>Total Value required.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackModsValueMin-" + i' *ngIf='rigCoverageAdjustment.controls.modsValue.hasError("min")'>Value must be {{valueLimits.mods.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackModsValueMax-" + i' *ngIf='rigCoverageAdjustment.controls.modsValue.hasError("max")'>Value must be {{valueLimits.mods.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
                                            <div class="valid-feedback" *ngIf="valuesRounded[i].modsValueRounded">Mods Value rounded.</div>
                                            <label class="text-gray-600 small" for="modsValueInput">Total Value of your Mods</label>
                                        </div>
                                    </div>
                                </div></span
                            ><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Additional Custom Parts & Equipment</span
                            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Provides coverage over and above your primary auto insurance policy. This coverage includes but is not limited to equipment, devices, accessories, enhancements, and changes other than those that are provided by the original manufacturer specifically for that model and trim, installed by the OEM, that are permanently installed and attached.</span></sbf-read-more-less></span
                            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select [attr.data-cy]='"modsDeductibleSelect-" + i' idAppend="modsDeductible" formControlName="modsDeductible" defaultText="Modifications Dedicutible" [optionsRecordsArray]="[eAcpeDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span
                        ></sbf-quote-ens-rates-card>
                    </div>
                    <div class="mb-3" data-cy="modsCoverageDeclinedCard" *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.modsCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">
                                Mods
                                <div class="d-sm-inline-block d-none">& Upgrades</div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center"><div class="text-link" [attr.data-cy]='"editModsFromDeclinedButton-" + i' (click)="addMods(i)">add</div></div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_mods_full.svg" alt="Mods svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue"
                                ><div class="d-flex justify-content-center align-items-center mt-3">
                                    <div class="text-center">
                                        <div class="text-lg text-secondary">MODIFICATION COVERAGE DECLINED</div>
                                        <button class="btn btn-sm rounded-pill btn-secondary-gradient text-white fw-500 mt-3" data-cy="addModsFromDeclinedInlineButton" (click)="addMods(i)" tabindex="0">
                                            <div class="d-flex align-items-center">
                                                <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                                                <div class="ms-1">Add Modification Coverage</div>
                                            </div>
                                        </button>
                                    </div>
                                </div></span
                            ></sbf-quote-ens-rates-card
                        >
                    </div>
                    <div class="mb-3" data-cy="camperCoverageCard" *ngIf="quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.camperCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">Camper</div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="text-link" [attr.data-cy]='"editCamperButton-" + i' (click)="editCamper(i)">edit</div>
                                <div class="mx-2">-</div>
                                <div class="text-link-danger" [attr.data-cy]='"removeCamperButton-" + i' (click)="removeCamper(i)">remove</div>
                            </div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_camper_full.svg" alt="camper svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue" *ngIf="editValues"
                                ><div class="row justify-content-start">
                                    <div class="col-xl-4 col-lg-6 col-12">
                                        <div class="form-floating mb-3">
                                            <input class="form-control valid-override" id="camperValueInput" [attr.data-cy]='"camperValueInput-" + i' autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="camperValueHelp" formControlName="camperValue" [class.is-invalid]="rigCoverageAdjustment.controls.camperValue.invalid" [class.is-valid]="rigCoverageAdjustment.controls.camperValue.valid" placeholder="camperValue" prefix=" $" mask="separator.0" thousandSeparator="," />
                                            <div class="invalid-feedback" *ngIf='rigCoverageAdjustment.controls.camperValue.hasError("required")'>Total Value required.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackCamperValueMin-" + i' *ngIf='rigCoverageAdjustment.controls.camperValue.hasError("min")'>Value must be {{valueLimits.camper.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackCamperValueMax-" + i' *ngIf='rigCoverageAdjustment.controls.camperValue.hasError("max")'>Value must be {{valueLimits.camper.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
                                            <div class="valid-feedback" *ngIf="valuesRounded[i].camperValueRounded">Camper Value rounded.</div>
                                            <label class="text-gray-600 small" for="camperValueInput">Camper Value</label>
                                        </div>
                                    </div>
                                </div></span
                            ><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Comprehensive Coverage</span
                            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Loss or damage from theft, vandalism, fire, earthquake, wind, hail, flood, animal collision, any cause except a collision with another vehicle.</span></sbf-read-more-less></span
                            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select [attr.data-cy]='"camperCompDeductibleSelect-" + i' idAppend="camperCompDeductible" formControlName="camperCompDeductible" defaultText="Camper Comprehensive Dedicutible" [optionsRecordsArray]="[eUnitCompDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span
                        ></sbf-quote-ens-rates-card>
                        <hr />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"></span><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Collision Coverage</span
                            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Loss or damage caused by impact with another object or upset of a travel trailer. Includes Truck Campers.</span></sbf-read-more-less></span
                            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select [attr.data-cy]='"camperCollDeductibleSelect-" + i' idAppend="camperCollDeductible" formControlName="camperCollDeductible" defaultText="Camper Collision Dedicutible" [optionsRecordsArray]="[eUnitCollDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span
                        ></sbf-quote-ens-rates-card>
                    </div>
                    <div class="mb-3" data-cy="camperCoverageDeclinedCard" *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.camperCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">Camper</div>
                            <div class="d-flex justify-content-between align-items-center"><div class="text-link" [attr.data-cy]='"addCamperFromDeclinedButton-" + i' (click)="addCamper(i)">add</div></div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_camper_full.svg" alt="camper svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue"
                                ><div class="d-flex justify-content-center align-items-center mt-3">
                                    <div class="text-center">
                                        <div class="text-lg text-secondary">CAMPER COVERAGE DECLINED</div>
                                        <button class="btn btn-sm rounded-pill btn-secondary-gradient text-white fw-500 mt-3" data-cy="addCamperFromDeclinedInlineButton" (click)="addCamper(i)" tabindex="0">
                                            <div class="d-flex align-items-center">
                                                <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                                                <div class="ms-1">Add Camper Coverage</div>
                                            </div>
                                        </button>
                                    </div>
                                </div></span
                            ></sbf-quote-ens-rates-card
                        >
                    </div>
                    <div class="mb-3" data-cy="trailerCoverageCard" *ngIf="quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.trailerCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">Trailer</div>
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="text-link" [attr.data-cy]='"editTrailerButton-" + i' (click)="editTrailer(i)">edit</div>
                                <div class="mx-2">-</div>
                                <div class="text-link-danger" [attr.data-cy]='"removeTrailerButton-" + i' (click)="removeTrailer(i)">remove</div>
                            </div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_trailer_full.svg" alt="trailer svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue" *ngIf="editValues"
                                ><div class="row justify-content-start">
                                    <div class="col-xl-4 col-lg-6 col-12">
                                        <div class="form-floating mb-3">
                                            <input class="form-control valid-override" id="trailerValueInput" [attr.data-cy]='"trailerValueInput-" + i' autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="trailerValueHelp" formControlName="trailerValue" [class.is-invalid]="rigCoverageAdjustment.controls.trailerValue.invalid" [class.is-valid]="rigCoverageAdjustment.controls.trailerValue.valid" placeholder="trailerValue" prefix=" $" mask="separator.0" thousandSeparator="," />
                                            <div class="invalid-feedback" *ngIf='rigCoverageAdjustment.controls.trailerValue.hasError("required")'>Total Value required.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackTrailerValueMin-" + i' *ngIf='rigCoverageAdjustment.controls.trailerValue.hasError("min")'>Value must be {{valueLimits.trailer.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
                                            <div class="invalid-feedback" [attr.data-cy]='"invalidFeedbackTrailerValueMax-" + i' *ngIf='rigCoverageAdjustment.controls.trailerValue.hasError("max")'>Value must be {{valueLimits.trailer.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
                                            <div class="valid-feedback" *ngIf="valuesRounded[i].trailerValueRounded">Trailer Value rounded.</div>
                                            <label class="text-gray-600 small" for="trailerValueInput">Trailer Value</label>
                                        </div>
                                    </div>
                                </div></span
                            ><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Comprehensive Coverage</span
                            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Loss or damage from theft, vandalism, fire, earthquake, wind, hail, flood, animal collision, any cause except a collision with another vehicle.</span></sbf-read-more-less></span
                            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select [attr.data-cy]='"trailerCompDeductibleSelect-" + i' idAppend="trailerCompDeductible" formControlName="trailerCompDeductible" defaultText="Trailer Comprehensive Dedicutible" [optionsRecordsArray]="[eUnitCompDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span
                        ></sbf-quote-ens-rates-card>
                        <hr />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"></span><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Collision Coverage</span
                            ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                                ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">Loss or damage caused by impact with another object or upset of a travel trailer. Includes Truck Campers.</span></sbf-read-more-less></span
                            ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select [attr.data-cy]='"trailerCollDeductibleSelect-" + i' idAppend="trailerCollDeductible" formControlName="trailerCollDeductible" defaultText="Trailer Collision Dedicutible" [optionsRecordsArray]="[eUnitCollDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span
                        ></sbf-quote-ens-rates-card>
                    </div>
                    <div class="mb-3" data-cy="trailerCoverageDeclinedCard" *ngIf="!quoteEnsFlowFormValue.rigSets[i]?.coverageSelect.trailerCoverage">
                        <div class="card-section-title d-flex justify-content-between align-items-center">
                            <div class="ms-2">Trailer</div>
                            <div class="d-flex justify-content-between align-items-center"><div class="text-link" [attr.data-cy]='"addTrailerFromDeclinedButton-" + i' (click)="addTrailer(i)">add</div></div>
                        </div>
                        <hr class="full mb-3" />
                        <sbf-quote-ens-rates-card
                            ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_trailer_full.svg" alt="trailer svg" /></span
                            ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue"
                                ><div class="d-flex justify-content-center align-items-center mt-3">
                                    <div class="text-center">
                                        <div class="text-lg text-secondary">TRAILER COVERAGE DECLINED</div>
                                        <button class="btn btn-sm rounded-pill btn-secondary-gradient text-white fw-500 mt-3" data-cy="addTrailerFromDeclinedInlineButton" (click)="addTrailer(i)" tabindex="0">
                                            <div class="d-flex align-items-center">
                                                <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                                                <div class="ms-1">Add Trailer Coverage</div>
                                            </div>
                                        </button>
                                    </div>
                                </div></span
                            ></sbf-quote-ens-rates-card
                        >
                    </div>
                </div>
                <ng-container *ngIf="rigCoverageAdjustmentsArray.controls.length &gt; 1">
                    <hr class="full mb-3" />
                    <div class="d-inline-block text-link-danger" [attr.data-cy]='"removeRigButton-" + i' (click)="removeRig(i)">remove rig</div></ng-container
                >
            </form>
        </div></ng-container
    >
</form>
<div class="tredder-card coverage-card mb-5">
    <div class="d-flex justify-content-center align-items-center">
        <div class="text-link d-flex align-items-center ms-2 my-3" data-cy="addAnotherRigButton" (click)="addAnotherRig()">
            <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
            <div class="ms-2">Add another rig</div>
        </div>
    </div>
</div>
