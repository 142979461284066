import type { BuildInfo } from '@common/models/build-info.model';

export const buildInfo: BuildInfo = {
    commitSHA: '7881e29322a33a9b9e6c08436fcd738a29ea3e45',
    branch: 'main',
    deployEnv: 'prod',
    version: '1.0.9',
    dateFormatted: '2024-08-27--00-05',
    dateISOString: '2024-08-27T00:05:20.880Z',
};
