import { Injectable } from '@angular/core';
import { PromotionKeys } from '@common/models';
// import { FrontEndProduct } from '@modules/admin/models';
// import { ResultsOrder, ResultsPlan, ResultsPricing } from '@start-bootstrap/website-shared-types';
import { AllParameters } from 'augmentations/facebook-pixel.augmentation';

import { EnvService, LogService, PlatformService, ScriptService, UtilityService } from '.';

// https://developers.google.com/analytics/devguides/collection/analyticsjs/command-queue-reference
// https://developers.google.com/analytics/devguides/collection/upgrade/analyticsjs#map_analyticsjs_fields_to_gtagjs_parameters
// https://developers.facebook.com/docs/facebook-pixel/reference#standard-events
// https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-ecommerce#measure_purchases

// GA4 Event
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag

// const GA_MEASUREMENT_ID = 'G-8W59QYVEPS';
const LOG_DEV_ANALYTICS = true;

const FACEBOOK_DISABLED = true;
const HOTJAR_DISABLED = true;
const CLARITY_DISABLED = false;

type CustomEventNames = CustomEventNamesQuote | CustomEventNamesEstimator;

type CustomEventNamesQuote =
    | 'quote_ens_intro'
    | 'quote_ens_pledge'
    | 'quote_ens_rig_name'
    | 'quote_ens_rig_details'
    | 'quote_ens_coverage_select'
    | 'quote_ens_mods_details'
    | 'quote_ens_camper_details'
    | 'quote_ens_trailer_details'
    | 'quote_ens_rig_review'
    | 'quote_ens_gear_conditional'
    | 'quote_ens_gear_details'
    | 'quote_ens_home_base'
    | 'quote_ens_personal_info'
    | 'quote_ens_rates'
    | 'quote_ens_purchase'
    | 'quote_ens_purchase_twenty_sixteen'
    | 'quote_ens_purchase_full'
    | 'quote_ens_rc2'
    | 'quote_ens_dec_pull'
    | 'quote_ens_documents'
    | 'quote_ens_payment'
    | 'quote_ens_payment_confirmed'
    | 'quote_ens_payment_error'
    | 'quote_ens_password'
    | 'quote_ens_retrieval_exclusion'
    | 'quote_ens_retrieval_success';

type CustomEventNamesEstimator =
    | 'est_view'
    | 'est_mods_gear'
    | 'est_camper_trailer'
    | 'est_get_estimate'
    | 'est_results'
    | 'est_start_a_quote'
    | 'est_mods'
    | 'est_gear'
    | 'est_camper'
    | 'est_trailer'
    | 'est_large_form';

type SignUpCategories = 'joinCommunity' | 'account' | 'overlandExpo';

export interface SendEventCustomOptions {
    action: CustomEventNames;
    category?: string;
    label?: string;
    value?: number;
    affiliateID?: string;
    marketingSource?: string;
}

@Injectable()
export class AnalyticsService {
    constructor(
        private platformService: PlatformService,
        private utilityService: UtilityService,
        private envService: EnvService,
        private logService: LogService,
        private scriptService: ScriptService
    ) {
        if (this.platformService.isBrowser) {
            // INFO: Disable this again.. will load in index.html for now
            // this._init();
        }
    }

    // async _init() {
    //     this.logService.info('Initializing Analytics module');
    //     setTimeout(async () => {
    //         const loaded = await this.scriptService.loadScript('gtag');
    //         if (!loaded) {
    //             this.logService.error('GTAG_NOT_LOADED');
    //             // throw new Error('GTAG_NOT_LOADED');
    //         }
    //         this.gtag('js', new Date());
    //         this.gtag('config', GA_MEASUREMENT_ID);
    //         // INFO: No longer need to listen to navigation events to update page_view.
    //         // Tag Manager does this automatically.
    //         // https://support.google.com/tagmanager/answer/12131703?hl=en
    //         // https://tagmanager.google.com/?utm_source=marketingplatform.google.com&utm_medium=et&utm_campaign=marketingplatform.google.com%2Fabout%2Ftag-manager%2F#/container/accounts/6059021197/containers/94697632/workspaces/0/activities/create/ogt_auto_events
    //     }, 1000);
    // }

    sendEventCustom(options: SendEventCustomOptions) {
        const gtagCustomParams: string | Gtag.CustomParams | Date | Gtag.EventNames = {};

        if (options.category) {
            gtagCustomParams['event_category'] = options.category;
        }
        if (options.label) {
            gtagCustomParams['event_label'] = options.label;
        }
        if (options.value) {
            gtagCustomParams['value'] = options.value;
        }
        if (options.affiliateID) {
            gtagCustomParams['affiliate_id'] = options.affiliateID;
        }
        if (options.marketingSource) {
            gtagCustomParams['marketing_source'] = options.marketingSource;
        }

        // INFO: Disable these above with variables if necessary
        this.gtag('event', options.action, gtagCustomParams);
        this.fbq('trackCustom', options.action, {
            event_category: options.category,
            event_label: options.label,
            value: options.value,
        });
        this.hotjar(options.action);
        this.clarity(options.action);
    }

    // sendEventViewItem(
    //     eventCategory: string,
    //     eventLabel: string,
    //     eventValue?: number,
    //     product?: FrontEndProduct
    // ) {
    //     this.gtag('event', 'view_item', {
    //         event_category: eventCategory,
    //         event_label: eventLabel,
    //         value: eventValue,
    //         items: product
    //             ? [
    //                   {
    //                       id: product.id,
    //                       name: product.displayName,
    //                       list_name: 'ProductCards',
    //                       brand: 'TODO_BRAND',
    //                       category: product.primaryCategory,
    //                       list_position: product.sortOrder,
    //                   },
    //               ]
    //             : undefined,
    //     });
    //     this.fbq('track', 'ViewContent', {
    //         content_category: eventCategory,
    //         content_name: eventLabel,
    //         value: eventValue,
    //     });
    // }

    sendEventSignUp(category: SignUpCategories, label?: string) {
        const gtagCustomParams: string | Gtag.CustomParams | Date | Gtag.EventNames = {
            event_category: category,
        };
        if (label) {
            gtagCustomParams['event_label'] = label;
        }

        const marketingSource = this.utilityService.localStorage.getItem('marketing_source');

        if (marketingSource) {
            gtagCustomParams['marketing_source'] = marketingSource;
        }

        this.gtag('event', 'sign_up', gtagCustomParams);
        this.fbq('track', 'CompleteRegistration', {
            content_category: category,
        });
    }

    sendEventLogin(category: 'local' | 'facebook' | 'github' | 'google' | 'twitter') {
        this.gtag('event', 'login', { event_category: category });
        this.fbq('trackCustom', 'login', {
            content_category: category,
        });
    }

    // sendEventBeginCheckout(category: 'stripe' | 'paypal', assemblingOrder: ResultsOrder) {
    //     let coupon: string | undefined;
    //     if (assemblingOrder.coupons.length === 1) {
    //         coupon = assemblingOrder.coupons[0].code;
    //     }
    //     this.gtag('event', 'begin_checkout', {
    //         event_category: category,
    //         items: assemblingOrder.lineItems.map(lineItem => ({
    //             id: lineItem.id,
    //             name: lineItem.pricing.displayName,
    //             category: lineItem.pricing.product?.primaryCategory,
    //             variant: lineItem.licenseType,
    //             quantity: lineItem.quantity,
    //             price:
    //                 (lineItem.pricing.salePrice
    //                     ? lineItem.pricing.salePrice
    //                     : lineItem.pricing.price) / 100,
    //         })),
    //         coupon,
    //     });
    //     this.fbq('track', 'InitiateCheckout', {
    //         content_category: category,
    //     });
    // }

    // sendEventSubscribe(plan: PlanForGuestResults) {
    //     this.gtag('event', 'subscribe', {
    //         event_category: 'stripe',
    //         items: [
    //             {
    //                 id: plan.id,
    //                 name: plan.name,
    //                 category: plan.pricingPeriod,
    //                 variant: plan.pricingLevel,
    //                 quantity: 1,
    //                 price: plan.price / 100,
    //             },
    //         ],
    //     });
    //     this.fbq('track', 'Subscribe', {
    //         content_category: 'stripe',
    //     });
    // }

    // sendEventPurchase(category: 'stripe' | 'paypal', order: ResultsOrder) {
    //     this.gtag('event', 'purchase', {
    //         event_category: category,
    //         transaction_id: order.id,
    //         value: order.total / 100,
    //         currency: 'USD',
    //         items: order.lineItems.map(lineItem => ({
    //             id: lineItem.id,
    //             name: lineItem.pricing.displayName,
    //             category: lineItem.pricing.product?.primaryCategory,
    //             variant: lineItem.licenseType,
    //             quantity: lineItem.quantity,
    //             price:
    //                 (lineItem.pricing.salePrice
    //                     ? lineItem.pricing.salePrice
    //                     : lineItem.pricing.price) / 100,
    //         })),
    //     });
    //     this.fbq('track', 'Purchase', {
    //         currency: 'USD',
    //         value: order.total / 100,
    //         content_category: category,
    //     });
    // }

    // sendEventAddToCart(pricing: ResultsPricing, listName: ListNames) {
    //     this.gtag('event', 'add_to_cart', {
    //         items: [
    //             {
    //                 id: pricing.id,
    //                 name: pricing.displayName,
    //                 list_name: listName,
    //                 brand: 'TODO_BRAND',
    //                 category: pricing.product?.primaryCategory,
    //                 variant: pricing.licenseType,
    //                 price: (pricing.salePrice ? pricing.salePrice : pricing.price) / 100,
    //             },
    //         ],
    //     });
    //     this.fbq('track', 'AddToCart', {
    //         content_ids: [pricing.id],
    //         content_name: pricing.displayName,
    //         currency: 'USD',
    //         value: (pricing.salePrice ? pricing.salePrice : pricing.price) / 100,
    //     });
    // }

    // removeFromCart(pricing: ResultsPricing) {
    //     this.gtag('event', 'remove_from_cart', {
    //         items: [
    //             {
    //                 id: pricing.product?.id,
    //                 name: pricing.product?.displayName,
    //                 brand: 'TODO_BRAND',
    //                 category: pricing.product?.primaryCategory,
    //                 variant: pricing.licenseType,
    //                 list_position: pricing.product?.sortOrder,
    //                 price: (pricing.salePrice ? pricing.salePrice : pricing.price) / 100,
    //             },
    //         ],
    //     });
    // }

    // productImpression(product: FrontEndProduct) {
    //     this.gtag('event', 'view_item_list', {
    //         items: [
    //             {
    //                 id: product.id,
    //                 name: product.displayName,
    //                 list_name: 'ProductCards',
    //                 brand: 'TODO_BRAND',
    //                 category: product.primaryCategory,
    //                 list_position: product.sortOrder,
    //             },
    //         ],
    //     });
    // }

    promotionImpression(promotion: PromotionKeys, slug?: string, delay?: number) {
        this.gtag('event', 'view_promotion', {
            promotions: [
                {
                    id: promotion,
                    category: slug,
                    delay,
                },
            ],
        });
    }

    promotionClicked(promotion: PromotionKeys, slug?: string) {
        this.gtag('event', 'select_content', {
            promotions: [
                {
                    id: promotion,
                    category: slug,
                },
            ],
        });
    }

    fbq(eventType: string, eventName: string, parameters?: AllParameters) {
        if (FACEBOOK_DISABLED) {
            return;
        }
        if (!this.envService.isProd || this.platformService.isServer) {
            if (LOG_DEV_ANALYTICS) {
                this.logService.info(
                    `### FACEBOOK_ANALYTICS: Not in production so logging analytics calls`
                );
                this.logService.info(`eventType | eventName | parameters -> `);
                this.logService.info({ eventType, eventName, parameters });
            }
        } else {
            if (parameters) {
                this.utilityService.window.fbq(eventType, eventName, parameters);
            } else {
                this.utilityService.window.fbq(eventType, eventName);
            }
        }
    }

    // eslint-disable-next-line complexity
    gtag(
        // command: 'config' | 'set' | 'js' | 'event',
        command: 'event',
        eventNameTargetIDOrConfig: string | Gtag.CustomParams | Date | Gtag.EventNames,
        eventParamsOrConfig?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
    ) {
        if (!this.envService.isProd || this.platformService.isServer) {
            if (LOG_DEV_ANALYTICS) {
                this.logService.info(
                    `### GOOGLE_ANALYTICS: Not in production so logging analytics calls`
                );
                this.logService.info(`command | eventName | eventParamsOrConfig -> `);
                this.logService.info({ command, eventNameTargetIDOrConfig, eventParamsOrConfig });
            }
        } else {
            // if (command === 'config') {
            //     this.utilityService.window.gtag(
            //         command,
            //         eventNameTargetIDOrConfig as string,
            //         eventParamsOrConfig as Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
            //     );
            // }
            // if (command === 'set') {
            //     this.utilityService.window.gtag(
            //         command,
            //         eventNameTargetIDOrConfig as Gtag.CustomParams
            //     );
            // }
            // if (command === 'js') {
            //     this.utilityService.window.gtag(command, eventNameTargetIDOrConfig as Date);
            // }
            if (command === 'event') {
                this.utilityService.window.gtag(
                    command,
                    eventNameTargetIDOrConfig as Gtag.EventNames | string,
                    eventParamsOrConfig as Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams
                );
            }
        }
    }

    hotjar(eventString: string) {
        if (HOTJAR_DISABLED) {
            return;
        }
        if (!this.envService.isProd || this.platformService.isServer) {
            if (LOG_DEV_ANALYTICS) {
                this.logService.info(`### HOTJAR: Not in production so logging analytics calls`);
            }
        } else {
            this.utilityService.window.hj('event', eventString);
        }
    }

    clarity(eventString: string) {
        if (CLARITY_DISABLED) {
            return;
        }
        if (!this.envService.isProd || this.platformService.isServer) {
            if (LOG_DEV_ANALYTICS) {
                this.logService.info(`### CLARITY: Not in production so logging analytics calls`);
            }
        } else {
            this.utilityService.window.clarity('event', eventString);
        }
    }
}
