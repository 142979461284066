import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { DriverMaritalStatus, driverMaritalStatusText } from '@backend-types/driver';
import { EnsPersonalInfoFormValue, QuoteRigSetFormValue } from '@backend-types/quote-flow-ens';
import { ModelFormGroup, ModelFormValue } from '@common/models';
import { DevUtilsModalKind } from '@common/models/dev-utils.model';
import { AnalyticsService, AssertionService, HotKeysService, UserService } from '@common/services';
import { validateBirthday } from '@common/validators';
import { DevUtilsModalService } from '@modules/dev/services';
import { QuoteRigSetFormService } from '@modules/quote/services';
import { format, subYears } from 'date-fns';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-personal-info-form',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-personal-info-form.component.html',
    styleUrls: ['quote-ens-personal-info-form.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteEnsPersonalInfoFormComponent,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteEnsPersonalInfoFormComponent,
        },
    ],
})
export class QuoteEnsPersonalInfoFormComponent
    implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy, Validator
{
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    hasAcknowledged!: boolean;

    subscription: Subscription = new Subscription();

    activeQuoteRigSetForm!: ModelFormGroup<QuoteRigSetFormValue>;

    minBirthday = format(subYears(new Date(), 100), 'yyyy-MM-dd');
    maxBirthday = format(subYears(new Date(), 16), 'yyyy-MM-dd');

    eDriverMaritalStatusText = driverMaritalStatusText;

    ensPersonalInfoForm: ModelFormGroup<EnsPersonalInfoFormValue> = this.fb.group({
        firstName: new FormControl<string | null>(null, [Validators.required]),
        lastName: new FormControl<string | null>(null, [Validators.required]),
        birthday: new FormControl<string | null>(null, [Validators.required, validateBirthday()]),
        email: new FormControl<string | null>(null, [Validators.required, Validators.email]),
        phone: new FormControl<string | null>(null, [Validators.required]),
        tcpaAgree: new FormControl<boolean | null>(true, [Validators.required]),
        ssn: new FormControl<string | null>(null),
        maritalStatus: new FormControl<DriverMaritalStatus | null>(null, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (ensPersonalInfo: ModelFormValue<EnsPersonalInfoFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private quoteRigSetFormService: QuoteRigSetFormService,
        private changeDetectorRef: ChangeDetectorRef,
        private devUtilsModalService: DevUtilsModalService,
        private title: Title,
        private analyticsService: AnalyticsService,
        private userService: UserService
    ) {
        this.title.setTitle('Tredder Quote - Personal Info');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_personal_info',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.ensPersonalInfoForm.patchValue({
                    firstName: 'Testy',
                    lastName: 'McTestface',
                    birthday: '1978-05-06',
                    email: `test_${new Date().valueOf()}@tredder.com`,
                    phone: '5551234567',
                    maritalStatus: DriverMaritalStatus.married,
                });
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowDown' }).subscribe(() => {
                this.devUtilsModalService.open(DevUtilsModalKind.personalInfo, {
                    writeValue: (value) => this.writeValue(value),
                });
            })
        );
        this.subscription.add(
            this.quoteRigSetFormService
                .getActiveQuoteRigSetForm$()
                .subscribe((activeQuoteRigSetForm) => {
                    this.activeQuoteRigSetForm = activeQuoteRigSetForm;
                    this.changeDetectorRef.detectChanges();
                })
        );
    }

    ngAfterViewInit() {
        this.subscription.add(
            this.userService.authUser$.subscribe((user) => {
                if (this.userService.isAdmin || this.userService.isAgent) {
                    return;
                }
                if (user) {
                    this.ensPersonalInfoForm.patchValue({
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                    });
                    this.emailControl.disable();
                    this.changeDetectorRef.detectChanges();
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (ensPersonalInfo: ModelFormValue<EnsPersonalInfoFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.ensPersonalInfoForm.valueChanges
                .pipe(
                    tap(() => {
                        if (this.ensPersonalInfoForm.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const ensPersonalInfo = this._ensPersonalInfoFormValue();
                        onChange(ensPersonalInfo);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.ensPersonalInfoForm.disable();
        } else {
            this.ensPersonalInfoForm.enable();
        }
    }

    writeValue(value: EnsPersonalInfoFormValue | null) {
        if (value === null) {
            this.ensPersonalInfoForm.reset({
                tcpaAgree: true,
            });
        }
        if (value) {
            this.ensPersonalInfoForm.setValue({
                ...value,
                ssn: null,
            });
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.ensPersonalInfoForm?.invalid) {
            return { ensPersonalInfoFormInvalid: true };
        }

        return null;
    }

    seeMyQuote() {
        this.next.emit();
    }

    private _ensPersonalInfoFormValue(): ModelFormValue<EnsPersonalInfoFormValue> {
        const { firstName, lastName, birthday, email, phone, tcpaAgree, ssn, maritalStatus } =
            this.ensPersonalInfoForm.getRawValue();

        this.assertionService.isDefinedOrThrow(firstName);
        this.assertionService.isDefinedOrThrow(lastName);
        this.assertionService.isDefinedOrThrow(birthday);
        this.assertionService.isDefinedOrThrow(email);
        this.assertionService.isDefinedOrThrow(phone);
        this.assertionService.isDefinedOrThrow(tcpaAgree);
        this.assertionService.isNotUndefinedOrThrow(ssn);
        this.assertionService.isDefinedOrThrow(maritalStatus);

        return {
            firstName,
            lastName,
            birthday,
            email,
            phone,
            tcpaAgree,
            ssn,
            maritalStatus,
        };
    }

    /* Accessor Methods */

    get firstNameControl() {
        return this.ensPersonalInfoForm.get('firstName') as FormControl;
    }

    get firstNameControlValid() {
        return this.firstNameControl.value && !this.firstNameControl.hasError('required');
    }

    get firstNameControlInvalid() {
        return this.firstNameControl.touched && this.firstNameControl.hasError('required');
    }

    get lastNameControl() {
        return this.ensPersonalInfoForm.get('lastName') as FormControl;
    }

    get lastNameControlValid() {
        return this.lastNameControl.value && !this.lastNameControl.hasError('required');
    }

    get lastNameControlInvalid() {
        return this.lastNameControl.touched && this.lastNameControl.hasError('required');
    }

    get birthdayControl() {
        return this.ensPersonalInfoForm.get('birthday') as FormControl;
    }

    get birthdayControlValid() {
        return (
            this.birthdayControl.value &&
            !(
                this.birthdayControl.hasError('required') ||
                this.birthdayControl.hasError('badBirthday')
            )
        );
    }

    get birthdayControlInvalid() {
        return (
            this.birthdayControl.touched &&
            (this.birthdayControl.hasError('required') ||
                this.birthdayControl.hasError('badBirthday'))
        );
    }

    get emailControl() {
        return this.ensPersonalInfoForm.get('email') as FormControl;
    }

    get emailControlValid() {
        return (
            this.emailControl.value &&
            !(this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get emailControlInvalid() {
        return (
            this.emailControl.touched &&
            (this.emailControl.hasError('required') || this.emailControl.hasError('email'))
        );
    }

    get phoneControl() {
        return this.ensPersonalInfoForm.get('phone') as FormControl;
    }

    get phoneControlValid() {
        return (
            this.phoneControl.value &&
            !(this.phoneControl.hasError('required') || this.phoneControl.hasError('mask'))
        );
    }

    get phoneControlInvalid() {
        return (
            this.phoneControl.touched &&
            (this.phoneControl.hasError('required') || this.phoneControl.hasError('mask'))
        );
    }

    get tcpaAgreeControl() {
        return this.ensPersonalInfoForm.get('tcpaAgree') as FormControl;
    }

    get tcpaAgreeControlValid() {
        return this.tcpaAgreeControl.value && !this.tcpaAgreeControl.hasError('required');
    }

    get tcpaAgreeControlInvalid() {
        return this.tcpaAgreeControl.touched && this.tcpaAgreeControl.hasError('required');
    }

    get ssnControl() {
        return this.ensPersonalInfoForm.get('ssn') as FormControl;
    }

    get ssnControlValid() {
        return this.ssnControl.value && !this.ssnControl.hasError('required');
    }

    get ssnControlInvalid() {
        return this.ssnControl.touched && this.ssnControl.hasError('required');
    }

    get maritalStatusControl() {
        return this.ensPersonalInfoForm.get('maritalStatus') as FormControl;
    }

    get maritalStatusControlValid() {
        return this.maritalStatusControl.value && !this.maritalStatusControl.hasError('required');
    }

    get maritalStatusControlInvalid() {
        return this.maritalStatusControl.touched && this.maritalStatusControl.hasError('required');
    }

    // eslint-disable-next-line complexity
    get allValid() {
        return (
            this.firstNameControlValid &&
            this.lastNameControlValid &&
            this.birthdayControlValid &&
            this.emailControlValid &&
            this.phoneControlValid &&
            this.tcpaAgreeControlValid &&
            this.maritalStatusControlValid
        );
    }
}
