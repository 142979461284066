import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormBuilder,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';
import { Title } from '@angular/platform-browser';
import {
    DriverLicenseStatus,
    driverLicenseStatusText,
    DriverYearsLicensed,
    driverYearsLicensedText,
} from '@backend-types/driver';
import { CurrentInsurance, currentInsuranceText } from '@backend-types/insurance-carriers';
import { RateCallTwoFormValue } from '@backend-types/quote-flow-ens';
import { DevUtilsModalKind, ModelFormGroup, ModelFormValue } from '@common/models';
import { AnalyticsService, AssertionService, HotKeysService } from '@common/services';
import { DevUtilsModalService } from '@modules/dev/services';
import { Subscription, tap } from 'rxjs';

@Component({
    selector: 'sbf-quote-ens-rc2',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './quote-ens-rc2.component.html',
    styleUrls: ['quote-ens-rc2.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: QuoteEnsRc2Component,
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: QuoteEnsRc2Component,
        },
    ],
})
export class QuoteEnsRc2Component implements OnInit, ControlValueAccessor, OnDestroy, Validator {
    @Input() formOnly = false;
    @Output() next = new EventEmitter<void>();
    @Output() back = new EventEmitter<void>();

    subscription: Subscription = new Subscription();

    driverLicenseStatusText = driverLicenseStatusText;
    driverYearsLicensedText = driverYearsLicensedText;
    currentInsuranceText = currentInsuranceText;
    numberOfResidentsText = { 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 };

    rateQuote2Form: ModelFormGroup<RateCallTwoFormValue> = this.fb.group({
        licenseStatus: new FormControl<DriverLicenseStatus | null>(null, [Validators.required]),
        yearsLicensed: new FormControl<DriverYearsLicensed | null>(null, [Validators.required]),
        currentInsurance: new FormControl<CurrentInsurance | null>(null, [Validators.required]),
        numberOfResidents: new FormControl<number | null>(null, [Validators.required]),
        overTwoViolations: new FormControl<boolean | null>(false, [Validators.required]),
        overTwelveLiftKit: new FormControl<boolean | null>(false, [Validators.required]),
    });

    onTouched: () => unknown = () => {};
    onChange = (rigDetails: ModelFormValue<RateCallTwoFormValue> | null) => {};

    constructor(
        private fb: FormBuilder,
        private hotKeysService: HotKeysService,
        private assertionService: AssertionService,
        private changeDetectorRef: ChangeDetectorRef,
        private devUtilsModalService: DevUtilsModalService,
        private title: Title,
        private analyticsService: AnalyticsService
    ) {
        this.title.setTitle('Tredder Quote - RC2');
        this.analyticsService.sendEventCustom({
            action: 'quote_ens_rc2',
        });
    }

    ngOnInit() {
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowRight' }).subscribe(() => {
                this.next.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowLeft' }).subscribe(() => {
                this.back.emit();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowUp' }).subscribe(() => {
                this.rateQuote2Form.patchValue({
                    licenseStatus: DriverLicenseStatus.valid,
                    yearsLicensed: DriverYearsLicensed.threeYearsOrMore,
                    currentInsurance: CurrentInsurance.progressive,
                    numberOfResidents: 1,
                });

                this.changeDetectorRef.detectChanges();
            })
        );
        this.subscription.add(
            this.hotKeysService.addShortcut$({ keys: 'shift.control.ArrowDown' }).subscribe(() => {
                this.devUtilsModalService.open(DevUtilsModalKind.rc2, {
                    writeValue: (value) => this.writeValue(value),
                });
            })
        );
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    registerOnChange(
        onChange: (rateQuote2: ModelFormValue<RateCallTwoFormValue> | null) => unknown
    ) {
        this.onChange = onChange;

        this.subscription.add(
            this.rateQuote2Form.valueChanges
                .pipe(
                    tap(() => {
                        if (this.rateQuote2Form.touched) {
                            this.onTouched();
                        }
                    })
                )
                .subscribe(() => {
                    try {
                        const rateQuote2 = this._rateQuote2FormValue();
                        onChange(rateQuote2);
                    } catch (error) {}
                })
        );
    }

    registerOnTouched(onTouched: () => unknown) {
        this.onTouched = onTouched;
    }

    setDisabledState(disabled: boolean) {
        if (disabled) {
            this.rateQuote2Form.disable();
        } else {
            this.rateQuote2Form.enable();
        }
    }

    writeValue(value: RateCallTwoFormValue | null) {
        if (value === null) {
            this.rateQuote2Form.reset({
                overTwoViolations: false,
                overTwelveLiftKit: false,
            });
        }
        if (value) {
            this.rateQuote2Form.setValue(value);
            this.changeDetectorRef.detectChanges();
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.rateQuote2Form?.invalid) {
            return { rateQuote2FormInvalid: true };
        }

        return null;
    }

    continue() {
        this.next.emit();
    }

    private _rateQuote2FormValue(): ModelFormValue<RateCallTwoFormValue> {
        const {
            licenseStatus,
            yearsLicensed,
            currentInsurance,
            numberOfResidents,
            overTwoViolations,
            overTwelveLiftKit,
        } = this.rateQuote2Form.value;

        this.assertionService.isDefinedOrThrow(licenseStatus);
        this.assertionService.isDefinedOrThrow(yearsLicensed);
        this.assertionService.isDefinedOrThrow(currentInsurance);
        this.assertionService.isDefinedOrThrow(numberOfResidents);
        this.assertionService.isDefinedOrThrow(overTwoViolations);
        this.assertionService.isDefinedOrThrow(overTwelveLiftKit);

        return {
            licenseStatus,
            yearsLicensed,
            currentInsurance,
            numberOfResidents: parseInt(numberOfResidents as unknown as string, 10),
            overTwoViolations,
            overTwelveLiftKit,
        };
    }

    /* Accessor Methods */

    get licenseStatusControl() {
        return this.rateQuote2Form.get('licenseStatus') as FormControl;
    }

    get licenseStatusControlValid() {
        return (
            this.licenseStatusControl.value &&
            !(
                this.licenseStatusControl.hasError('required') ||
                this.licenseStatusControl.hasError('min') ||
                this.licenseStatusControl.hasError('max')
            )
        );
    }

    get licenseStatusControlInvalid() {
        return (
            (this.licenseStatusControl.touched ||
                this.assertionService.isDefined(this.licenseStatusControl.value)) &&
            (this.licenseStatusControl.hasError('required') ||
                this.licenseStatusControl.hasError('min') ||
                this.licenseStatusControl.hasError('max'))
        );
    }

    get yearsLicensedControl() {
        return this.rateQuote2Form.get('yearsLicensed') as FormControl;
    }

    get yearsLicensedControlValid() {
        return (
            this.yearsLicensedControl.value &&
            !(
                this.yearsLicensedControl.hasError('required') ||
                this.yearsLicensedControl.hasError('min') ||
                this.yearsLicensedControl.hasError('max')
            )
        );
    }

    get yearsLicensedControlInvalid() {
        return (
            (this.yearsLicensedControl.touched ||
                this.assertionService.isDefined(this.yearsLicensedControl.value)) &&
            (this.yearsLicensedControl.hasError('required') ||
                this.yearsLicensedControl.hasError('min') ||
                this.yearsLicensedControl.hasError('max'))
        );
    }

    get currentInsuranceControl() {
        return this.rateQuote2Form.get('currentInsurance') as FormControl;
    }

    get currentInsuranceControlValid() {
        return (
            this.currentInsuranceControl.value &&
            !(
                this.currentInsuranceControl.hasError('required') ||
                this.currentInsuranceControl.hasError('min') ||
                this.currentInsuranceControl.hasError('max')
            )
        );
    }

    get currentInsuranceControlInvalid() {
        return (
            (this.currentInsuranceControl.touched ||
                this.assertionService.isDefined(this.currentInsuranceControl.value)) &&
            (this.currentInsuranceControl.hasError('required') ||
                this.currentInsuranceControl.hasError('min') ||
                this.currentInsuranceControl.hasError('max'))
        );
    }

    get numberOfResidentsControl() {
        return this.rateQuote2Form.get('numberOfResidents') as FormControl;
    }

    get numberOfResidentsControlValid() {
        return (
            this.numberOfResidentsControl.value &&
            !(
                this.numberOfResidentsControl.hasError('required') ||
                this.numberOfResidentsControl.hasError('min') ||
                this.numberOfResidentsControl.hasError('max')
            )
        );
    }

    get numberOfResidentsControlInvalid() {
        return (
            (this.numberOfResidentsControl.touched ||
                this.assertionService.isDefined(this.numberOfResidentsControl.value)) &&
            (this.numberOfResidentsControl.hasError('required') ||
                this.numberOfResidentsControl.hasError('min') ||
                this.numberOfResidentsControl.hasError('max'))
        );
    }

    get allValid() {
        return (
            this.licenseStatusControlValid &&
            this.yearsLicensedControlValid &&
            this.currentInsuranceControlValid &&
            this.numberOfResidentsControlValid
        );
    }
}
