<div class="tredder-card coverage-card mb-5" *ngIf="quoteEnsFlowFormValue">
    <div class="text-start mb-3"><h2>Overland Gear</h2></div>
    <hr class="full mb-3" />
    <div class="mb-3" data-cy="gearCoverageCard" *ngIf="quoteEnsFlowFormValue.gearCoverage">
        <form [formGroup]="overlandGearAdjustmentsForm">
            <sbf-quote-ens-rates-card
                ><span #ensRatesCardImage ngProjectAs="ensRatesCardImage"><img class="max-width-image mb-md-5 mb-3" src="/assets/img/svg/overland-design/od_gear_full.svg" alt="gear png" /></span
                ><span #ensRatesCardValue ngProjectAs="ensRatesCardValue" *ngIf="editValues"
                    ><div class="row justify-content-start">
                        <div class="col-xl-4 col-lg-6 col-12">
                            <div class="form-floating mb-3">
                                <input class="form-control valid-override" id="gearValueInput" data-cy="gearValueInput" autocomplete="off" type="text" pattern="[0-9]*" aria-describedby="gearValueHelp" formControlName="gearValue" [class.is-invalid]="gearValueControl.invalid" [class.is-valid]="gearValueControl.valid" placeholder="gearValue" prefix=" $" mask="separator.0" thousandSeparator="," />
                                <div class="invalid-feedback" *ngIf='gearValueControl.hasError("required")'>Total Value required.</div>
                                <div class="invalid-feedback" data-cy="invalidFeedbackMin" *ngIf='gearValueControl.hasError("min")'>Value must be {{valueLimits.gear.min | currency : 'USD' : 'symbol' : '1.0'}} or greater.</div>
                                <div class="invalid-feedback" data-cy="invalidFeedbackMax" *ngIf='gearValueControl.hasError("max")'>Value must be {{valueLimits.gear.max | currency : 'USD' : 'symbol' : '1.0'}} or less.</div>
                                <div class="valid-feedback" *ngIf="gearValueRounded">Gear Value rounded.</div>
                                <label class="text-gray-600 small" for="gearValueInput">Gear Value</label>
                            </div>
                        </div>
                    </div></span
                ><span #ensRatesCardTitle ngProjectAs="ensRatesCardTitle">Overland Gear</span
                ><span #ensRatesCardText ngProjectAs="ensRatesCardText"
                    ><sbf-read-more-less [maxWords]="12"><span #passedText ngProjectAs="passedText">We insure direct, abrupt, and accidental physical loss to your personal property, minus any applicable deductible shown on the Declarations or elsewhere in the policy, unless the loss is excluded elsewhere in this endorsement. Not permanently attached to a Motor Vehicle or Trailer.</span></sbf-read-more-less></span
                ><span #ensRatesCardAction ngProjectAs="ensRatesCardAction"><sbf-input-select data-cy="gearDeductibleSelect" idAppend="gearDeductible" [formControl]="gearDeductible" defaultText="Gear Deductible" [optionsRecordsArray]="[gearDeductibleText]" displayTextAppend=" Deductible" styleClass="tredder-shaded"></sbf-input-select></span><span #ensRatesCardSwitch ngProjectAs="ensRatesCardSwitch"><div class="text-link-danger" data-cy="removeGearButton" (click)="removeGear()">remove</div></span></sbf-quote-ens-rates-card
            >
        </form>
    </div>
    <div class="mb-3" *ngIf="!quoteEnsFlowFormValue.gearCoverage">
        <div class="d-flex justify-content-evenly align-items-center flex-wrap mb-3">
            <div class="text-link d-flex align-items-center ms-2 me-3" data-cy="addGearButton" (click)="addGear()">
                <i-bs name="plus-circle" width="1.25em" height="1.25em"></i-bs>
                <div class="ms-2">Add gear</div>
            </div>
        </div>
    </div>
</div>
