import { Injectable } from '@angular/core';
import { UtmLocalStorage } from '@backend-types/utm';
import { SuccessfulMediaStorage } from '@common/models';
import compare from 'just-compare';

import { UtilityService } from './utility.service';

@Injectable()
export class UtmService {
    constructor(private utilityService: UtilityService) {}

    get utm(): UtmLocalStorage | null {
        return this.utilityService.getStoredObject<UtmLocalStorage>('utm_storage') || null;
    }

    set utm(utmStorage: Omit<UtmLocalStorage, 'initiated'>) {
        const existingUtm = this.utm;

        if (existingUtm) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { initiated, ...existingUtmMinusInitiated } = existingUtm;
            if (!compare(existingUtmMinusInitiated, utmStorage)) {
                this.utilityService.storeObject('utm_storage', {
                    ...utmStorage,
                    initiated: new Date().toISOString(),
                });
            }
        } else {
            this.utilityService.storeObject('utm_storage', {
                ...utmStorage,
                initiated: new Date().toISOString(),
            });
        }
    }

    get successfulMedia(): SuccessfulMediaStorage | null {
        return this.utilityService.getStoredObject<SuccessfulMediaStorage>('sm_storage') || null;
    }

    set successfulMedia(successfulMediaStorage: SuccessfulMediaStorage) {
        this.utilityService.storeObject('sm_storage', successfulMediaStorage);
    }
}
