export enum MarketingSource {
    facebook = 'facebook',
    google = 'google',
    overlandExpo = 'overlandExpo',
    overlandExpoQR = 'overlandExpoQR',
}

export interface SuccessfulMediaStorage {
    transactionId: string;
}
